import BaseModal from "../basemodal";
import { Item, Lista } from "./styles";

export default function OpcoesDropdown({ opcoes, aoEscolher, fecharModal }){

  function aoClicarItem(valor){
    aoEscolher(valor);
    fecharModal();
  }

  return (
    <BaseModal fecharModal={fecharModal}>
      <Lista>
        {opcoes.map(item => <Item onClick={() => aoClicarItem(item.value)}>{item.label}</Item>)}
      </Lista>
    </BaseModal>
  )
}