import React, { useEffect, useState } from "react";
import { Icon } from 'semantic-ui-react';
import { postAtestado } from "../../../API/APIDisciplina";
import storage from "../../../storage";
import aux from "../../../utils/auxiliar";
import Alert from "../../alert/Alert";
import Loading from "../../loading/Loading";

import BaseModal from "../../basemodal";
import { BodyModal } from "../style";
import {
  CompanyInfo,
  EmployeeInfo,
  SubmitButton,
  TicketContainer,
  TicketHeader
} from "./styles";


export default function DemonstrativoPonto(props) {

  const fileInputRef = React.createRef();
  const [show, setShow] = useState(false)
  const [justify, setJustify] = useState(false);
  const [showCardPonto, setShowPonto] = useState(false);
  const [jsxTicketView, setJsxTickect] = useState(null);
  const [showObs, setShowObs] = useState(false);
  const [obsPonto, setObsPonto] = useState("");
  const [countCaracter, setCounter] = useState(0);
  const [justificativa, setJustificativa] = useState("");
  const [_filesToUpload, setFileUpload] = useState([]);
  const [solicitaAtestado, setSolicitaAtestado] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, activeLoading] = useState(false);

  useEffect(() => {
    if (props.item) {
      resolveTicket(props.item.item, props.item.type, props.item.isFalta);
    }
  }, [props.item])

  function returnType(numberOfType) {

    switch (numberOfType) {
      case 1:
        return "AUTOMÁTICA"
      case 2:
        return "ELETRÔNICA"
      case 3:
        return "MOVIMENTAÇÃO"
      case 4:
        return "AJUSTE"
      case 5:
        return "PONTO FALTA MARCAÇÃO"
      default: return ""
    }
  }




  async function sendAtestado() {
    try {

      if (_filesToUpload.length === 0) {
        showMessage("Nenhum arquivo selecionado.");
        return;
      }

      if (justificativa === "") {
        showMessage("Campo 'DESCRIÇÃO' não preenchido.");
        return;
      }

      activeLoading(true)

      const conteudoArquivo = storage.getCodeDisciplaAtestado();
      const response = await postAtestado(_filesToUpload, conteudoArquivo, justificativa);

      if (response) {
        activeLoading(false)
        showMessage("Envio Concluído");
      } else {
        activeLoading(false)
        showMessage("Erro ao Enviar Atestado");
      }
      closeBox();
    } catch (error) {
      closeBox();
      activeLoading(false)
      showMessage("Erro ao Enviar Atestado");
    }
  }



  function resolveTicket(item, type, isFalta) {
    try {

      // Se for difente de dois passar a verificar o campo de obs. Só abrir observacao se tiver
      //ocorrencia: 1 atraso, 2 saida atencipada, 3 outros

      let open = false;
      if (!item) return;

      if (isFalta && item.temAtestado) {
        openJustify();
        return
      }

      if (item[type].tpMarcacao === "0" || item[type].tpMarcacao === 0) return;


      let jsxTicket = null
      let empresaLogada = "";

      if (item[type].tpMarcacao === '2') {

        setShowPonto(true);

        try {
          empresaLogada = storage.getEmpresaLogada();
          empresaLogada = JSON.parse(empresaLogada);
        } catch (error) {

        }


        const cnpj = empresaLogada.cnpj;
        const razaoSocial = empresaLogada.value;
        let tipoM = returnType(parseInt(item[type].tpMarcacao));

        if ((item[type].temOcorrencia > 0) && (item[type].mensagemOcorrencia !== "")) {
          setObsPonto(item[type].mensagemOcorrencia);
          setShowObs(true);
        }

        open = true;

        jsxTicket = (
          <>
            <CompanyInfo>
              <h6 id="name">{razaoSocial}</h6>
              <h6 id="cnpj">CNPJ : {cnpj}</h6>
            </CompanyInfo>
            <EmployeeInfo>
              <h6><span className="titleModalPonto">Nome </span>  <strong>{props.userName}</strong></h6>
              <h6><span className="titleModalPonto">PIS </span>  <strong>{props.pis}</strong></h6>
              <h6><span className="titleModalPonto">Data </span>  <strong>{aux.formataDataUTC(item.data)}</strong></h6>
              <h6><span className="titleModalPonto">Hora </span>  <strong>{aux.extraiHorario(item[type].horario)}</strong></h6>
              <h6><span className="titleModalPonto">Tipo de Marcação </span> <strong>{tipoM}</strong></h6>
            </EmployeeInfo>
          </>
        )
        setJsxTickect(jsxTicket)
      } else {

        if ((item[type].temOcorrencia > 0) && (item[type].mensagemOcorrencia !== "")) {
          setObsPonto(item[type].mensagemOcorrencia);
          setShowObs(true);
          open = true;
        } else {
          return;
        }

      }

      if (open) {
        if (item[type].temAtestado) setSolicitaAtestado(true)
        return setShow(true)
      }

    } catch (error) {
      alert(error)
    }
  }


  async function addFileToUpload(list) {

    let files = [];

    for (let i = 0; i < list.length; i++) {
      try {
        activeLoading(true);
        files.push({
          fileName: list[i].name,
          file: await aux.getBase64(list[i])
        });
        activeLoading(false);
      } catch (error) {
        activeLoading(false);
      }
    }
    setFileUpload([..._filesToUpload, ...files])
  }


  function handleJustificativa(e) {
    const countCaracter = e.target.value.length;
    setJustificativa(e.target.value);
    setCounter(countCaracter);

  }

  function openDialogAnexos() {
    fileInputRef.current.click();
  }


  function removeAnexo(index) {
    let itensAtuais = _filesToUpload.map(o => o);
    itensAtuais.splice(index, 1);
    setFileUpload(itensAtuais)
  }



  function openJustify() {
    const codeAtestado = storage.getCodeDisciplaAtestado();

    if (props.item.item.permiteAtestado === 0) {
      return showMessage('Tempo limite para justificativa expirado');
    }


    if (codeAtestado === 0 || codeAtestado === '0') {
      showMessage('Empresa não parametrizada para envio de atestado.');
      return;
    }

    setShow(true)
    setJustify(true)
    setShowPonto(false);
    setJsxTickect(null);
    setShowObs(false);
    setObsPonto("");
    setCounter(0);
    setJustificativa("");
    setFileUpload([]);
  }

  function handleFileUpload(e) {
    if (e.target.files[0].size > 20971520) {
      return showMessage('Arquivo maior que o permitido');
    }
    addFileToUpload(e.target.files)
  }

  function closeBox() {
    setShow(false);
    setShowPonto(false);
    setJsxTickect(null);
    setShowObs(false);
    setObsPonto("");
    setCounter(0);
    setJustificativa("");
    setFileUpload([]);
    setJustify(false)
  }



  function showMessage(message) {
    setShowAlert(true);
    setMessage(message)
  }


  if (loading) {
    return <Loading show={true} />
  }

  if (showAlert) {
    return <Alert show={true} title="" message={message} onClose={() => { setShowAlert(false) }} />
  }

  if (!show) return null


  // if (justify) {
  //   return (
  //     <BackgroundAtestado>
  //       <input type="file" accept="image/*,application/*" style={{ display: "none" }} ref={fileInputRef} onChange={handleFileUpload} multiple />
  //       <BoxAtestado>
  //         <TitleAtestado>Envio de Atestado</TitleAtestado>
  //         <Close>
  //           <IconButton onClick={closeBox}>
  //             <CloseIcon style={{ color: "#000", fontSize: 22 }} />
  //           </IconButton>
  //         </Close>

  //         <FormContainer>
  //           <Picture color={_filesToUpload.length > 0 ? '#47A369' : '#cccc'}>
  //             <IconButton onClick={openDialogAnexos}>
  //               <PhotoIcon style={{ color: "#fff", fontSize: 42 }} />
  //             </IconButton>
  //           </Picture>

  //           <FileListContainer>
  //             {_filesToUpload.map((o, index) => {
  //               return (
  //                 <div key={index}>
  //                   <FileContainer>
  //                     <p >{o.fileName}</p>
  //                     <Icon name='trash' className="thrashIcon" onClick={() => removeAnexo(index)} />
  //                   </FileContainer>
  //                 </div>
  //               )
  //             })}
  //           </FileListContainer>

  //           <DescriptionContainer>
  //             <label className="label-input">Descrição *</label>
  //             <Input
  //               space
  //               value={justificativa}
  //               onChange={handleJustificativa}
  //               title="Descrição"
  //               placeholder={"Descrição"}
  //               type={'text'}
  //               maxLength={30}
  //             />
  //             <span className="spa-caracter-count">{countCaracter}/30</span>
  //           </DescriptionContainer>

  //           <SubmitButton onClick={sendAtestado}>
  //             ENVIAR ATESTADO
  //           </SubmitButton>

  //         </FormContainer>
  //       </BoxAtestado>
  //       {/* <div className="box-modal-ponto">
  //         <div className="header-modal-ponto">
  //           <div className="text-header-modal-ponto">
  //             {!justify && <p><Icon name='clock outline' className="" /> PONTO DETALHADO</p>}
  //             {justify && <p><Icon name='file' className="" /> ENVIO DE ATESTADO</p>}
  //           </div>
  //           <Icon name="times circle outline" className="iconCloseModalPonto" id="closeModalPontoDetail" onClick={closeBox} size="large" />
  //         </div>
  //         <div className="body-modal-ponto">
  //           <div className="screenJustify" style={{ height: justify ? "100%" : 0 }} >
  //             <div className="bodyJustificativa">
  //               <input type="file" accept="image/*,application/*" className="inputFile" ref={fileInputRef} onChange={handleFileUpload} multiple />

  //               <textarea maxLength={30} className="edtJustificativa" placeholder="Descrição *" value={justificativa} onChange={handleJustificativa}></textarea>
  //               <span className="spa-caracter-count">{countCaracter}/30</span>

  //               <Button className="btnJustificativa" onClick={openDialogAnexos}>
  //                 <Icon size="large" name='attach' className="" /> ANEXAR ARQUIVO
  //          </Button>

  //               <Button className="btnJustificativa verde" onClick={sendAtestado}>
  //                 ENVIAR ATESTADO
  //         </Button>

  //               <h6><strong>Anexos</strong> - tamanho máximo por arquivo : 20MB</h6>
  //               <div className="arquivosAnexos">
  //                 {_filesToUpload.map((o, index) => {
  //                   return (
  //                     <div key={index}>
  //                       <div className="contentAnexos">
  //                         <p>{o.fileName}</p>
  //                         <Icon name='trash' className="thrashIcon" onClick={() => removeAnexo(index)} />
  //                       </div>
  //                     </div>
  //                   )
  //                 })}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div> */}
  //     </BackgroundAtestado>
  //   )
  // }



  return (
    <>
      <BaseModal titulo={"Ponto detalhada"} fecharModal={closeBox}>

          <BodyModal>
            {
              showCardPonto && <TicketContainer>
                <TicketHeader>
                  <h6>Comprovante de Registro</h6>
                </TicketHeader>
                {jsxTicketView}
              </TicketContainer>
            }


            {
              showObs && <div className="boxInfoModalPonto">
                <TicketContainer>
                  <TicketHeader className="infoAdd" alignTitle={'left'}>
                    <h6><Icon name="warning" className="warningIcon"/> Observação</h6>
                  </TicketHeader>
                  <p id="infoAdd">{obsPonto !== "" ? obsPonto : "não há informações adicionais."}</p>
                  {solicitaAtestado && <SubmitButton onClick={openJustify}>Justificar</SubmitButton>}
                </TicketContainer>
              </div>
            }


            {/* Tela de justificativa */}
            {/* <div className="screenJustify" style={{ height: justify ? "100%" : 0 }} >
              <div className="bodyJustificativa">
                <input type="file" accept="image/*,application/*" className="inputFile" ref={fileInputRef} onChange={handleFileUpload} multiple />

                <textarea maxLength={30} className="edtJustificativa" placeholder="Descrição *" value={justificativa} onChange={handleJustificativa}></textarea>
                <span className="spa-caracter-count">{countCaracter}/30</span>

                <Button className="btnJustificativa" onClick={openDialogAnexos}>
                  <Icon size="large" name='attach' className="" /> ANEXAR ARQUIVO
             </Button>

                <Button className="btnJustificativa verde" onClick={sendAtestado}>
                  ENVIAR ATESTADO
             </Button>

                <h6><strong>Anexos</strong> - tamanho máximo por arquivo : 20MB</h6>
                <div className="arquivosAnexos">
                  {_filesToUpload.map((o, index) => {
                    return (
                      <div key={index}>
                        <div className="contentAnexos">
                          <p>{o.fileName}</p>
                          <Icon name='trash' className="thrashIcon" onClick={() => removeAnexo(index)} />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div> */}

        </BodyModal>
    </BaseModal>
    </>
  )
}