
import storage from "./../storage";
import jwt from "jsonwebtoken";
import { save } from 'save-file'
import dayjs from "dayjs";
import moment from "moment";

const invalidsCpf = [
	'00000000000',
	'11111111111',
	'22222222222',
	'33333333333',
	'44444444444',
	'55555555555',
	'66666666666',
	'77777777777',
	'88888888888',
	'99999999999'
]


const aux = {

	extraiHorario: function (dateTime) {
		const date = new Date(dateTime);
		let hora = date.getUTCHours().toString();
		let minutos = date.getUTCMinutes().toString();
		if (hora.length === 1) hora = "0" + hora
		if (minutos.length === 1) minutos = "0" + minutos
		return `${hora}:${minutos}`
	},


	getFirstDayOfCurrentMonth: function () {
		const now = new Date();
		const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
		return firstDay;
	},

	formataDataUTC: function (strData, withHour, withDayOfWeek) {


		if (!strData || strData === "") return;

		let aux = strData.split("T")
		let data = dayjs(aux[0])

		let horas = aux[1].split(":");

		if (horas.length === 0) {
			horas[0] = '00'
			horas[1] = '00'
		}

		let day = data.get('date');
		let month = parseInt(data.get('month')) + 1;
		let year = data.get('year');
		let hour = horas[0];
		let minutes = horas[1];

		let daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

		if (day.toString().length === 1) day = '0' + day;
		if (month.toString().length === 1) month = '0' + month;

		if (hour.toString().length === 1) hour = '0' + hour
		if (minutes.toString().length === 1) minutes = '0' + minutes;

		let auxDataZero = `${day}/${month}/${year}`
		if (auxDataZero === '30/12/1899') return ""

		if (withDayOfWeek) {
			return `${daysOfWeek[data.get('day')]} - ${day}/${month}/${year}`
		}

		if (day && month && hour) {
			return withHour ? `${day}/${month}/${year}  ${hour}:${minutes}` : `${day}/${month}/${year}`
		} else {
			return "";
		}
	},

	downloadFile: function (file, fileName) {
		try {
			save(file, fileName);
		} catch (error) {
			alert(error);
			console.log(error);
		}
	},

	isObjEmpty: function (obj) {
		for (var prop in obj) {
			if (obj.hasOwnProperty(prop))
				return false;
		}
		return true;
	},

	isResponseValid: function (response) {

		const { status } = response.data;
		if (status !== 0 && status !== 22) {
			return false
		} else {
			return true;
		}
	},

	decodeJwt: function (token) {

		const jsonwebdecoded = jwt.decode(token);

		if (!jsonwebdecoded) return false;

		return jsonwebdecoded;
	},

	isPasswordExpired: function () {
		return false;
	},

	isTokenLicencaExpired: function () {

		const token = storage.getLicensa();
		const jsonwebdecoded = this.decodeJwt(token);
		if (!jsonwebdecoded) return true;

		const { exp } = jsonwebdecoded;

		try {
			if (!exp) return true;
			let current_time = Date.now() / 1000;
			if (exp < current_time) {
				return true
			} else {
				return false
			}
		} catch (err) {
			return false;
		}
	},

	isTokenAcessoExpired: function () {
		const token = storage.getaccess_token();

		const jsonwebdecoded = this.decodeJwt(token);

		if (!jsonwebdecoded) return true;

		const { exp } = jsonwebdecoded;
		try {
			if (!exp) return true;
			let current_time = Date.now() / 1000;
			if (exp < current_time) {
				return true
			} else {
				return false
			}
		} catch (err) {
			return false;
		}
	},

	testeAsync: async () => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(1);
			}, 2000);
		})
	},

	getHeaderPadrao: function () {
		return {
			'Authorization': `Bearer ${storage.getTokenLoginPlusLicenca()}`,
			'Content-Type': 'application/json'
		};
	},

	encodeToUTF8: async function (file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsText(file, "ISO-8859-4");
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error)
		});
	},

	getBase64: async function (file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader(file);
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error)
		});
	},

	getArrayBuffer: async function (file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader(file);
			reader.readAsArrayBuffer(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error)
		});
	},

	base64Encode: function (str) {
		return btoa(str);
	},

	hexEncode: function (str) {
		var hex, i;

		var result = "";
		for (i = 0; i < str.length; i++) {
			hex = str.charCodeAt(i).toString(16);
			result += ("000" + hex).slice(-4);
		}

		return result
	},

	hexDecode: function (str) {
		var j;
		var hexes = str.match(/.{1,4}/g) || [];
		var back = "";
		for (j = 0; j < hexes.length; j++) {
			back += String.fromCharCode(parseInt(hexes[j], 16));
		}

		return back;
	},

	getNameUser: function (full) {

		let userName = this.decodeJwt(storage.getLicensa()).hkUserName;
		if (full) return userName
		userName ? userName = userName.split(" ") : userName = ""
		if (userName.length > 0) return userName[0];
		return "";
	},

	getBlobFromBase64: (base64) => {

		const raw = window.atob(base64);
		const rawLength = raw.length;
		const blobArray = new Uint8Array(new ArrayBuffer(rawLength));

		for (let i = 0; i < rawLength; i++) {
			blobArray[i] = raw.charCodeAt(i);
		}

		const blob = new Blob([blobArray], { type: 'application/pdf' });

		return blob;
	},

	getTypeFile: function (link) {
		const str = link.toLowerCase();

		const arrFromStr = str.split("");
		let ex = "";

		for (let i = arrFromStr.length - 1; i > 0; i--) {
			if (arrFromStr[i] === ".") break;
			ex = arrFromStr[i] + ex;
		}
		return ex
	},

	isImage: function (link) {

		const strLink = link.toLowerCase();
		const extImage = ['jpeg', 'jpg', 'png', 'gif', 'bmp'];

		const arrFromStr = strLink.split("");
		let ex = "";

		for (let i = arrFromStr.length - 1; i > 0; i--) {
			if (arrFromStr[i] === ".") break;
			ex = arrFromStr[i] + ex;
		}
		return {
			hadAnImage: extImage.indexOf(ex) !== -1,
			extensao: ex
		}
	},

	formatReal(valor) {
		if (!valor || valor === "") return;
		const formatado = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
		return formatado;
	},

	cpfMask: cpf => {
		return cpf
			.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
			.replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d{1,2})/, '$1-$2')
			.replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
	},

	cnvMask: cnv => {
		return cnv
			.replace(/\D/g, '')
			.replace(/(\d{5})(\d)/, '$1/$2') 
	},

	validaCpf: (CPF) => {
		CPF.replace(/[.-]/g, '');

		let soma = 0;
		let resto;
		let inputCPF = CPF;
		let i = 0;
		if (invalidsCpf.find(c => c === CPF)) return false;

		if (inputCPF === '00000000000') return false;
		for (i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
		resto = (soma * 10) % 11;

		if ((resto === 10) || (resto === 11)) resto = 0;
		if (resto !== parseInt(inputCPF.substring(9, 10))) return false;

		soma = 0;
		for (i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
		resto = (soma * 10) % 11;

		if ((resto === 10) || (resto === 11)) resto = 0;
		if (resto !== parseInt(inputCPF.substring(10, 11))) return false;
		return true;
	},

	getDependentes: function () {
		const dependentes = [
			{
				nome: "Alan Machado",
				grauparentesco: "PAI",
				datanascimento: "14/06/2000",
				idade: 20,
				cidade: "Sao Paulo",
				estado: "SP",
				rg: "4444444444",
				cpf: "888888888",
				endereco: "Rua 'sdadsad"
			},
			{
				nome: "Joao",
				grauparentesco: "Irmao",
				datanascimento: "14/06/2000",
				idade: 20,
				cidade: "Sao Paulo",
				estado: "SP",
				rg: "4444444444",
				cpf: "888888888",
				endereco: "Rua 'sdadsad"
			},
			{
				nome: "Rodolfo",
				grauparentesco: "irmao",
				datanascimento: "14/06/2000",
				idade: 20,
				cidade: "Sao Paulo",
				estado: "SP",
				rg: "4444444444",
				cpf: "888888888",
				endereco: "Rua 'sdadsad"
			},
			{
				nome: "Omar",
				grauparentesco: "Irmao",
				datanascimento: "14/06/2000",
				idade: 20,
				cidade: "Sao Paulo",
				estado: "SP",
				rg: "4444444444",
				cpf: "888888888",
				endereco: "Rua 'sdadsad"
			},
		];


		return dependentes;
	},


	getAlocacoes: function () {
		const alocacoes = [
			{
				vaga: "1234",
				vinculo: "operacional",
				origem: "Plantonista",
				cliente: "Reserva Técnica",
				local: "INSS",
				alocacao: "EFETIVO",
				itemcontrato: "SEGURANA",
				inicio: "03/09/2019",
				fim: "",
				usuario: "Otavio"
			},
			{
				vaga: "1234",
				vinculo: "operacional",
				origem: "Plantonista",
				cliente: "Reserva Técnica",
				local: "INSS",
				alocacao: "EFETIVO",
				itemcontrato: "SEGURANA",
				inicio: "03/09/2019",
				fim: "",
				usuario: "Otavio"
			},
			{
				vaga: "1234",
				vinculo: "operacional",
				origem: "Plantonista",
				cliente: "Reserva Técnica",
				local: "INSS",
				alocacao: "EFETIVO",
				itemcontrato: "SEGURANA",
				inicio: "03/09/2019",
				fim: "",
				usuario: "Otavio"
			},
			{
				vaga: "1234",
				vinculo: "operacional",
				origem: "Plantonista",
				cliente: "Reserva Técnica",
				local: "INSS",
				alocacao: "EFETIVO",
				itemcontrato: "SEGURANA",
				inicio: "03/09/2019",
				fim: "",
				usuario: "Otavio"
			},
		];


		return alocacoes;
	},


	enumeraPagePontos: function (pontos) {
		let pageItem = 1;
		pontos.map((o, index) => {
			let numeroItem = index + 1;
			if (numeroItem) {
				let aux = Math.ceil(numeroItem / 7);
				if (aux > pageItem) pageItem++
			}
			o.page = pageItem;

			return o;
		});

		return pontos;
	},

	enumeraArray: function (array) {
		let pageItem = 1;
		array.map((o, index) => {
			let numeroItem = index + 1;
			if (numeroItem) {
				let aux = Math.ceil(numeroItem / 7);
				if (aux > pageItem) pageItem++
			}
			o.page = pageItem;

			return o;
		});

		return array;
	},

	geraExcecao: function (message) {
		throw new Error(message);
	},


	formatDateYYYYMMDD: function (date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  },

	answerAPP: () => {

		const isMobile = storage.isMobile();

		if (isMobile) {
			try {
				window.ReactNativeWebView.postMessage(JSON.stringify({ acao: "Ready" }));
			} catch (error) {
				alert(error)
				console.log(error);
			}
		}
	},

	getRouteById: function (id) {
		switch (id) {
			case 18560:
				return "/sac"
			case 18561:
				return "/ferias"
			case 18562:
				return "/cursos"
			case 18563:
				return "/beneficios"
			case 18569:
				return "/dadosuser"
			case 18570:
        return "/ponto"
      case 18685:
        return "/monitoramentofuncionario"
      case 18693:
        return "/documentos"
      case 18701:
        return "/noticias"
			default: return "/404"
		}
	},

	resolveIcon: function (code) {
		switch (code) {
			case 18570:
				return "calendar check"
			case 18569:
				return "user"
			case 18561:
				return "plane"
			case 18563:
				return "gift"
			case 18562:      
				return "student"
			case 18560:
				return "phone"
      case 18685:
        return "chart bar outline"
      case 18693:
        return "file alternate"
      case 18701:
        return "globe"
			default:
				return ""
		}
	},


	trataErroAPI: function (response) {

		let codeErr = 0;

		if (response.data) {
			if (!response.data.hasOwnProperty('status')) return { err: true, message: "Property status not found" }
			else {
				codeErr = response.data.status
			}
		}

		switch (codeErr) {
			case 0:
				return { err: false }
			case 1:
				return { err: true, message: "Erro Desconhecido" }
			case 2:
				return { err: true, message: "Autenticação: Falha ao decodificar Base64 da autorização, Token Inválido" }
			case 3:
				return { err: true, message: "Autenticação: Falha ao carregar JSON da autorização, Token Inválido" }
			case 4:
				return { err: true, message: "Autenticação: Tipo de autenticação de licença inválido" }
			case 5:
				return { err: true, message: "Autenticação: Falha na leitura da autenticação de login" }
			case 6:
				return { err: true, message: "Autenticação: Falha na leitura da autenticação de acesso" }
			case 7:
				return { err: true, message: "Licença: Token de licença inválido" }
			case 8:
				return { err: true, message: "Licença: Token de licença Expirado" }
			case 9:
				return { err: true, message: "Parâmetro: IDApp inválido" }
			case 10:
				return { err: true, message: "Acesso: Token de acesso inválido" }
			case 11:
				return { err: true, message: "Acesso: Token de acesso Expirad" }
			case 12:
				return { err: true, message: "Acesso: Usuário ou Senha inválidos" }
			case 13:
				return { err: true, message: "Acesso: Parâmetro Empresa inválido ou não informado" }
			case 14:
				return { err: true, message: "Acesso: Parâmetro Usuário inválido ou não informado " }
			case 15:
				return { err: true, message: "Acesso: Parâmetro Senha inválida ou não informada " }
			case 16:
				return { err: true, message: "Acesso: Senha de acesso Expirada" }
			case 17:
				return { err: true, message: "Acesso: Falha ao carregar parâmetros da nova senha" }
			case 18:
				return { err: true, message: "Acesso: Nova senha é inferior a 8 dígitos" }
			case 19:
				return { err: true, message: "Acesso: Nova senha e confirmação são diferentes" }
			case 20:
				return { err: true, message: "Acesso: Ocorreu uma falha no cadastro da nova senha" }
			case 21:
				return { err: true, message: "Ações: Não foram encontradas ações disponíveis para o App " }
			case 22:
				return { err: true, message: "Ações: Ação não liberada" }
			case 23:
				return { err: false, message: "SAC: Funcionário sem chamado" }
			case 24:
				return { err: true, message: "SAC: Parâmetro Chamado inválido" }
			case 25:
				return { err: true, message: "SAC: Chamado não encontrado" }
			case 26:
				return { err: true, message: "SAC: Chamado não pertence ao funcionário " }
			case 27:
				return { err: true, message: "SAC: Parâmetro Ocorrência inválido " }
			case 28:
				return { err: true, message: "SAC: Parâmetro Prioridade inválido " }
			case 29:
				return { err: true, message: "SAC: Parâmetro Título inválido" }
			case 30:
				return { err: true, message: "SAC: Parâmetro Texto inválido" }
			case 31:
				return { err: true, message: "SAC: Falha na inclusão do chamado" }
			case 32:
				return { err: true, message: "SAC: Falha ao carregar parâmetros de inclusão de chamado " }
			case 33:
				return { err: false, message: "SAC: Não existem Ocorrências cadastradas para o App" }
			case 34:
				return { err: true, message: "SAC: Não existem tipos de anexos cadastradas para chamados." }
			case 35:
				return { err: true, message: "Licença: Falha na leitura do Token de licença " }
			case 36:
				return { err: true, message: "Ponto Dia: Falha na leitura dos parâmetros " }
			case 37:
				return { err: true, message: "Ponto Dia: Parâmetro Empresa Inválido " }
			case 38:
				return { err: true, message: "Ponto Dia: Parâmetro Funcionário Inválido" }
			case 39:
				return { err: true, message: "Ponto Dia: Parâmetro Data Inválido" }
			case 40:
				return { err: true, message: "Ponto Dia: Funcionário não está ativo nesta data." }
			case 41:
				return { err: true, message: "SAC: Falha ao carregar parâmetros de inclusão de anexos" }
			case 42:
				return { err: true, message: "SAC: Parâmetro Conteúdo Arquivo inválido" }
			case 43:
				return { err: true, message: "SAC: Parâmetro Nome Original inválido" }
			case 44:
				return { err: true, message: "SAC: Parâmetro Descrição inválido" }
			case 45:
				return { err: true, message: "SAC: Parâmetro Anexo inválido" }
			case 46:
				return { err: true, message: "SAC: Falha na inclusão de anexos " }
			case 47:
				return { err: true, message: "SAC: Falha ao carregar parâmetros de leitura de anexo" }
			case 48:
				return { err: true, message: "SAC: Parâmetro chave anexo inválido" }
			case 49:
				return { err: false, message: "SAC: Não foi encontrado um anexo para o chamado" }
			case 50:
				return { err: true, message: "SAC: Falha na leitura do anexo" }
			case 51:
				return { err: true, message: "Funcionário: Falha ao carregar funcionário" }
			case 52:
				return { err: false, message: "SAC: Nenhum anexo disponível " }
			case 53:
				return { err: true, message: "Dados Cadastrais: Falha na listagem dos dados cadastrais" }
			case 54:
				return { err: false, message: "Dados Cadastrais: Sem dependentes cadastrados" }
			case 55:
				return { err: true, message: "Dados Cadastrais: Falha na listagem de dependentes" }
			case 56:
				return { err: true, message: "Férias: Falha ao listar opções de férias " }
			case 57:
				return { err: true, message: "Férias: Sem opções de férias cadastradas " }
			case 58:
				return { err: true, message: "Ponto: Falha na leitura dos parâmetros de ponto" }
			case 59:
				return { err: true, message: "Ponto: Data Inicial inválida" }
			case 60:
				return { err: true, message: "Ponto: Data Final inválida" }
			case 61:
				return { err: true, message: "Ponto: Data Final menor que Data Inicial" }
			case 62:
				return { err: true, message: "Ponto: Falha na listagem dos pontos" }
			case 63:
				return { err: false, message: "Ponto: Não existem pontos para o período" }
			case 64:
				return { err: true, message: "Cursos: Falha ao listar histórico de cursos " }
			case 65:
				return { err: false, message: "Cursos: Não existem cursos cadastrados para o funcionário " }
			case 66:
				return { err: true, message: "Cursos: Falha ao listar turmas" }
			case 67:
				return { err: false, message: "Cursos: Não existem turmas agendadas" }
			case 68:
				return { err: true, message: "Benefícios: Falha ao carregar parâmetros" }
			case 69:
				return { err: true, message: "Benefícios: Parâmetro Ano inválido (1900-2100)" }
			case 70:
				return { err: true, message: "Benefícios: Parâmetro Mês inválido (1-12)" }
			case 71:
				return { err: true, message: "Benefícios: Falha ao carregar benefícios " }
			case 72:
				return { err: false, message: "Benefícios: Sem lançamentos de benefícios para o período  " }
			case 73:
				return { err: true, message: "Ocorr. Disciplinar: Parâmetros inválidos" }
			case 74:
				return { err: true, message: "Ocorr. Disciplinar: Data Incial maior que Data Final" }
			case 75:
				return { err: true, message: "Ocorr. Disciplinar: Funcionário sem ocorrências" }
			case 76:
				return { err: true, message: "Ocorr. Disciplinar: Parâmetros incorretos para inclusão de ocorrência" }
			case 77:
				return { err: true, message: "Ocorr. Disciplinar: Falha na inclusão da ocorrência" }
			case 78:
				return { err: true, message: "Ocorr. Disciplinar: Falha na inclusão da ocorrência'" }
			case 79:
				return { err: true, message: "Ocorr. Disciplinar: Falha na leitura de parâmetros do anexo" }
			case 80:
				return { err: true, message: "Ocorr. Disciplinar: Falha na inclusão do anexo" }
			case 81:
				return { err: true, message: "Ocorr. Disciplinar: Conteúdo inválido" }
			case 82:
				return { err: true, message: "Ocorr. Disciplinar: Nome original do arquivo inválido" }
			case 83:
				return { err: true, message: "Ocorr. Disciplinar: Descrição inválida" }
			case 84:
				return { err: true, message: "Ocorr. Disciplinar: Histórico não localizado" }
			case 85:
				return { err: true, message: "Ocorr. Disciplinar: Código padrão de atestado não definido" }
			case 124:
				return { err: true, message: "Dados Cadastrais: Lista de UFs não disponível" }
			case 125:
				return { err: true, message: "Dados Cadastrais: Falha ao buscar UFs" }
			case 126:
				return { err: true, message: "Dados Cadastrais: Lista de Localidades não disponível" }
			case 127:
				return { err: true, message: "Dados Cadastrais: Falha ao buscar Localidades" }
			case 128:
				return { err: true, message: "Dados Cadastrais: Tipos de Logradouros não disponível", codeErr: codeErr }
			case 129:
				return { err: true, message: "Dados Cadastrais: Falha ao buscar Tipos de Logradouros", codeErr: codeErr }
			case 130:
				return { err: true, message: "Dados Cadastrais: Parâmetros inválidos para atualização de endereço", codeErr: codeErr }
			case 131:
				return { err: true, message: "Dados Cadastrais: Falha ao atualizar o endereço", codeErr: codeErr }
			case 132:
				return { err: true, message: "Dados Cadastrais: Chave de endereço inválida", codeErr: codeErr }
			case 133:
				return { err: true, message: "Dados Cadastrais: Localidade/UF inválidos", codeErr: codeErr }
			case 134:
				return { err: true, message: "Dados Cadastrais: Tipo logradouro inválido", codeErr: codeErr }
			case 135:
				return { err: true, message: "Dados Cadastrais: Funcionário já possui endereço cadastrado", codeErr: codeErr }
			case 136:
				return { err: true, message: "Dados Cadastrais: Funcionário já possui um telefone pendente de confirmação", codeErr: codeErr }
			case 137:
				return { err: true, message: "Dados Cadastrais: Funcionário já possui um telefone pendente de confirmação", codeErr: codeErr }
			case 1001:
				return { err: false, message: "Logo empresa: logo not found", codeErr: codeErr }
			case 1002:
				return { err: false, message: "licenca: usuário não autorizado" }
			default: return { err: true, message: "Erro Desconhecido" }
		}
	},

	getUfs: function () {

		const ufs = [{ "codUf": 1, "uf": "AC", "nomeUf": "ACRE" }, { "codUf": 2, "uf": "AL", "nomeUf": "ALAGOAS" }, { "codUf": 3, "uf": "AP", "nomeUf": "AMAP\u00C1" }, { "codUf": 4, "uf": "AM", "nomeUf": "AMAZONAS" }, { "codUf": 5, "uf": "BA", "nomeUf": "BAHIA" }, { "codUf": 6, "uf": "CE", "nomeUf": "CEAR\u00C1" }, { "codUf": 7, "uf": "DF", "nomeUf": "DISTRITO FEDERAL" }, { "codUf": 8, "uf": "ES", "nomeUf": "ESP\u00CDRITO SANTO" }, { "codUf": 10, "uf": "GO", "nomeUf": "GOI\u00C1S" }, { "codUf": 11, "uf": "MA", "nomeUf": "MARANH\u00C3O" }, { "codUf": 12, "uf": "MT", "nomeUf": "MATO GROSSO" }, { "codUf": 13, "uf": "MS", "nomeUf": "MATO GROSSO DO SUL" }, { "codUf": 14, "uf": "MG", "nomeUf": "MINAS GERAIS" }, { "codUf": 15, "uf": "PA", "nomeUf": "PAR\u00C1" }, { "codUf": 16, "uf": "PB", "nomeUf": "PARA\u00CDBA" }, { "codUf": 17, "uf": "PR", "nomeUf": "PARAN\u00C1" }, { "codUf": 18, "uf": "PE", "nomeUf": "PERNAMBUCO" }, { "codUf": 19, "uf": "PI", "nomeUf": "PIAU\u00CD" }, { "codUf": 20, "uf": "RJ", "nomeUf": "RIO DE JANEIRO" }, { "codUf": 21, "uf": "RN", "nomeUf": "RIO GRANDE DO NORTE" }, { "codUf": 22, "uf": "RS", "nomeUf": "RIO GRANDE DO SUL" }, { "codUf": 23, "uf": "RO", "nomeUf": "ROND\u00D4NIA" }, { "codUf": 9, "uf": "RR", "nomeUf": "RORAIMA" }, { "codUf": 25, "uf": "SC", "nomeUf": "SANTA CATARINA" }, { "codUf": 26, "uf": "SP", "nomeUf": "S\u00C3O PAULO" }, { "codUf": 27, "uf": "SE", "nomeUf": "SERGIPE" }, { "codUf": 24, "uf": "TO", "nomeUf": "TOCANTINS" }];
		return ufs.map(e => {
			return {
				text: e.uf,
				value: e.codUf
			}
		})
	},

	isZeroDate: function (date) {
		try {
			const aux = date.replace('Z', '');
			const year = moment(aux).get('year');
			return year === 1899;
		} catch (error) {
			return true;
		}
	},

	invertDateString: function (date) {
		const year = date.substring(4, 8);
		const month = date.substring(2, 4);
		const day = date.substring(0, 2);
		const strDate = `${year}${month}${day}`;
		return strDate;
	},

	getToday: function () {
		const date = new Date();
		return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`
	}

}

export default aux;







