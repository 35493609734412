import styled, { css } from "styled-components";
import Skeleton from 'react-loading-skeleton';


const textColor = '#3c4856';
const inputStyle = css`
  font-size: 16px;
  padding: 8px 10px;
  border: 0.5px solid #eee;
  border-radius: 8;
  background: #eef0f2;
  color: ${textColor};
  align-items: center;
  margin-bottom: 20px;
`

export const Input = styled.input`
  ${inputStyle}
  width: 100%;
  ::placeholder {
    color: ${textColor};
  }
`

export const Dropdown = styled.button`
  ${inputStyle}
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`


export const Label = styled.label`
  font-weight: bold;
  color: ${textColor};
`


export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
`


export const BotaoAtestado = styled.label`
  border: none;
  width: fit-content;
  padding: 10px;
  background: #eef0f2;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`


export const ArquivoAnexo = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #eee;

  button {
    background: none;
    border: none;
  }

`


export const FormAtestado = styled.form`
  display: flex;
  flex-direction: column;
`

export const BotaoEnviar = styled.button`
  background: ${props => props.disabled ? '#cecece':'#2991D6'};
  border: none;
  color: #fff;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  padding: 5px;
`


export const Mock = styled(Skeleton)`
  width: 100%;
  height: 30px;
  margin: 10px 0;
`