import { useContext, useEffect, useMemo, useRef, useState } from "react";
import APIAtestado from "../../../API/APIAtestado";
import { ShowMessageContext } from "../../../contexts/showmessage";
import aux from "../../../utils/auxiliar";

export default function useAtestado({ fecharModal, finalizarAcao, alocacaoMaoObra }){
  const valoresIniciais = {
    dataInicial: null,
    dataFinal: null,
    crm: '',
    cid: '',
    medico: '',
    descricao: '',
    tipoOcorrencia: null,
    tipoCRM: 0,
    orgao: '',
    nomeHospital: '',
    relHospital: 0
  }

  const [valores, setValores] = useState(valoresIniciais);
  const [configuracaoCampos, setConfiguracaoCampos] = useState([]);

  const [tiposOcorrencia, setTiposOcorrencia] = useState([]);
  const [opcoesTipoOcorrenciaAberto, setOpcoesTipoOcorrenciaAberto] = useState(false);
  const [opcoesTipoCRMAberto, setOpcoesTipoCRMAberto] = useState(false);
  const [modalHospitalAberto, setModalHospitalAberto] = useState(false);

  const [categoriaOcorrencia, setCategoriaOcorrencia] = useState(0);
  const [nomeHospital, setNomeHospital] = useState('');

  const [anexos, setAnexos] = useState([]);
  const [statusEnvioAnexos, setStatusEnvioAnexos] = useState([]);
  const [erroAnexos, setErroAnexos] = useState(null);

  const [camposObrigatoriosPorTipo, setCamposObrigatoriosPorTipo] = useState([]);

  const [carregando, setCarregando] = useState(false);
  const [enviandoAtestado, setEnviandoAtestado] = useState(false);

  const [buscandoMedico, setBuscandoMedico] = useState();

  const ultimoCRMDigitado = useRef('');

  const { show } = useContext(ShowMessageContext);


  useEffect(() => {
    iniciarComponente();
  }, [])


  async function iniciarComponente(){
    setCarregando(true);
     
    try{
      await buscarTiposOcorrencia();
      await carregarCampos();
    }catch{
      setCarregando(false)
    }finally{
      setCarregando(false)
    }
    
   
  }


  async function buscarTiposOcorrencia(){
    try{
      const resposta = await APIAtestado.obterTiposOcorrencia();
      if (resposta.errorMessage) return;

      const tiposFormatados = formatarTiposOcorrencia(resposta);
      setTiposOcorrencia(tiposFormatados);

    }catch(e){
      alert(e);
    }
  }



  async function carregarCampos() {
    try {
      const resposta = await APIAtestado.obterCamposAtestado();

      if (resposta.errorMessage) return;

      setConfiguracaoCampos(resposta);

      let aux = [];

      aux.push(resposta.filter((i) => {
        if ((i.nome === 'medico' && i.obrigatorio) || (i.nome === 'crm' && i.obrigatorio) || (i.nome === 'cid' && i.obrigatorio)) return i
        else return false
      }))

      aux.push(resposta.filter((i) => {
        if (i.nome === 'orgao' && i.obrigatorio) return i
        else return false
      }))


      setCamposObrigatoriosPorTipo(aux)


    } catch (error) {
      throw new Error(`[carregarCampos]: ${error}`)
    }
  }



  function formatarTiposOcorrencia(tiposOcorrencia) {
    const aux = tiposOcorrencia.map(item => {
      return {
        label: item.descricao,
        value: item.ocorrenciaDisciplina,
        code: item.hasOwnProperty('tipo') ? item.tipo : 500
      }
    })

    return aux;
  }



  function abrirOpcoesTipoOcorrencia(){
    setOpcoesTipoOcorrenciaAberto(true);
  }




  
  function fecharOpcoesTipoOcorrencia(){
    setOpcoesTipoOcorrenciaAberto(false);
  }





  function abrirOpcoesTipoCRM(){
    setOpcoesTipoCRMAberto(true);
  }






  function fecharOpcoesTipoCRM(){
    setOpcoesTipoCRMAberto(false)
  }


  function abrirModalHospital(){
    setModalHospitalAberto(true);
  }


  function fecharModalHospital(){
    setModalHospitalAberto(false);
  }


  function selecionarHospital(nome, id){
    alterarValor(id, 'relHospital');
    setNomeHospital(nome);
  }


  function alterarValor(valor, campo){

    if(campo === 'tipoOcorrencia'){
      const tipoOcorrenciaSelecionada = tiposOcorrencia.find(item => item.value === valor);

      if(tipoOcorrenciaSelecionada)  setCategoriaOcorrencia(tipoOcorrenciaSelecionada.code);
      
      return setValores({...valoresIniciais, tipoOcorrencia: valor})

    }
    setValores({...valores, [campo]: valor})
  }



  async function anexarArquivos(e){
    const arquivos = e.target.files;

    let anexosAux = [];
    for(const arquivo of arquivos){
      try{
        anexosAux.push({
          nome: arquivo.name,
          file: await aux.getBase64(arquivo)
        })
      }catch(e){
        show(e);
      }
      
    }


    setAnexos([...anexos, ...anexosAux]);
  }


  function desanexarArquivosEnviados() {
    const anexosAux = anexos.filter((item, index) => statusEnvioAnexos[index] === false);
    setStatusEnvioAnexos([]);
    setAnexos([...anexosAux]);
    setErroAnexos(null);
  }




  function excluirArquivosAnexos(arquivoIndex){
    const anexosAux = anexos.filter((item, index) => index !== arquivoIndex);

    setAnexos(anexosAux);
  }



  async function buscarMedicoPorCRM() {
    if (valores.crm) {
      try {
        if (Number(valores.crm) <= 0) return;
        setBuscandoMedico(true);
        const response = await APIAtestado.buscarMedicoPorCRM(valores.crm, valores.tipoCRM);
        if (!response.error) {
          setValores({ ...valores, medico: response.data.nomeMedico })
        }
      } catch (error) {
        throw new Error(`findDoctorByCrm: ${error}`)
      } finally {
        setBuscandoMedico(false);
      }
    }
  }


  function aoFocarCRM() {
    if (valores.crm) ultimoCRMDigitado.current = valores.crm;
    else {
      ultimoCRMDigitado.current = ''
    }
  }



  async function submeterAtestado(){
    try {

      setEnviandoAtestado(true);

      const body = {
        DtInicio: valores.dataInicial,
        DtFim: valores.dataFinal,
        CRM: valores.crm,
        NomeMedico: valores.medico,
        CID: valores.cid,
        TipoOcorrencia: valores.tipoOcorrencia,
        alocacaoMaoObra,
        descricao: valores.descricao,
        tpcrm: valores.tipoCRM,
        tipo: categoriaOcorrencia,
        relHospital: valores.relHospital,
        nomeHospital: nomeHospital
      }


      const setUp = await APIAtestado.obterSetUpAtestado(body);

      if (setUp.hasOwnProperty('errorMessage') && setUp.errorMessage) {
        show(`Algo deu errado: ${setUp.errorMessage}`, true);
        return;
      }

      let enviado = 0;
      for (const [index, file] of anexos.entries()) {
        const statusEnvioAux = statusEnvioAnexos;
        try {

          const body = {
            ...setUp,
            nomeOriginal: file.nome,
            descricao: valores.descricao,
            anexo: `data:image/png;base64,${file.base64}`,
          }

          console.log(body);

          const result = await APIAtestado.enviarAtestado(body);


          if (!result.hasOwnProperty('errorMessage')) {
            statusEnvioAux[index] = true;
            setStatusEnvioAnexos([...statusEnvioAnexos]);
            enviado++
          } else {
            statusEnvioAux[index] = false;
          }
        } catch {
          statusEnvioAux[index] = false;
        }
      }

      await new Promise(resolve => setTimeout(resolve, 1000));

      setEnviandoAtestado(false);
      if (enviado === anexos.length) {
        show('Atestado enviado com sucesso.')
        if (finalizarAcao) finalizarAcao()
        else fecharModal();
      } else {
        setErroAnexos(`Ocorreu um erro ao enviar ${anexos.length - enviado} dos arquivos.`);

      }
    } catch (error) {
      console.log(error)
    } finally {
      setEnviandoAtestado(false);
    }
  }



  const tiposCRM = [{ label: 'Médico', value: 1 }, { label: 'Dentista', value: 2 }]


  

  const tipoOcorrenciaSelecionada = tiposOcorrencia.find(item => item.value === valores['tipoOcorrencia']);
  const tipoCRMSelecionado = tiposCRM.find(item => item.value === valores['tipoCRM']);


  const camposObrigatorios = useMemo(() => configuracaoCampos.filter(item => item.obrigatorio && item.nome !== 'periodo'), [configuracaoCampos]);
  const nomesCamposObrigatorios = useMemo(() => camposObrigatorios.map(item => item.nome), [camposObrigatorios]);

  let todosCamposObrigatoriosPreenchidos = useMemo(() => {

    if (categoriaOcorrencia === 1) {
      const result = camposObrigatoriosPorTipo[0].every((item) => !!valores[item.nome])
      return result;
    }

    if (categoriaOcorrencia === 2) {
      return camposObrigatoriosPorTipo[1].every((item) => !!valores[item.nome])
    }

    return true;
  }, [camposObrigatorios, valores]);




  if (todosCamposObrigatoriosPreenchidos) {
    if (((categoriaOcorrencia === 1) || (categoriaOcorrencia === 2)) && anexos.length === 0) {
      todosCamposObrigatoriosPreenchidos = false;
    }
    if (!valores.dataInicial || !valores.dataFinal) {
      todosCamposObrigatoriosPreenchidos = false;
    }
    if (categoriaOcorrencia === 0) {
      todosCamposObrigatoriosPreenchidos = false;
    }
    if (categoriaOcorrencia === 500 && anexos.length === 0) {
      todosCamposObrigatoriosPreenchidos = false;
    }
  }



  const todosValoresValidos = useMemo(() => !!valores.descricao && todosCamposObrigatoriosPreenchidos, [valores, anexos]);
  return {
    valoresIniciais,
    valores,
    configuracaoCampos,
    tiposOcorrencia,
    opcoesTipoOcorrenciaAberto,
    opcoesTipoCRMAberto,
    modalHospitalAberto,
    categoriaOcorrencia,
    nomeHospital,
    anexos,
    statusEnvioAnexos,
    erroAnexos,
    camposObrigatoriosPorTipo,
    carregando,
    enviandoAtestado,
    buscandoMedico,
    ultimoCRMDigitado,
    abrirOpcoesTipoOcorrencia,
    fecharOpcoesTipoOcorrencia,
    abrirOpcoesTipoCRM,
    fecharOpcoesTipoCRM,
    abrirModalHospital,
    fecharModalHospital,
    selecionarHospital,
    alterarValor,
    anexarArquivos,
    desanexarArquivosEnviados,
    excluirArquivosAnexos,
    buscarMedicoPorCRM,
    aoFocarCRM,
    submeterAtestado,
    tiposCRM,
    tipoOcorrenciaSelecionada,
    tipoCRMSelecionado,
    camposObrigatorios,
    nomesCamposObrigatorios,
    todosCamposObrigatoriosPreenchidos,
    todosValoresValidos
  }
}