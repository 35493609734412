import { useState } from "react";
import { BotaoFechar, BotaoNavegacao, Container, ContainerImagem, ContainerLista, MiniaturaImagem, Overlay } from "./style";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";

export default function Galeria({ imagens, fechar }){
  const [imagemSelecionada, setImagemSelecionada] = useState(0);

  function selecionarImagem(index){
    setImagemSelecionada(index);
  }


  return(
    <Overlay>
      
      <BotaoFechar onClick={fechar}>
        <IoMdClose size={25} color="#fff"/>
      </BotaoFechar>

      <Container>
        <ContainerImagem>

          <BotaoNavegacao onClick={() => selecionarImagem(imagemSelecionada - 1)} disabled={imagemSelecionada === 0}>
            <FaChevronLeft size={25} color="#fff"/>
          </BotaoNavegacao>

          <img src={imagens[imagemSelecionada]}/>

          <BotaoNavegacao onClick={() => selecionarImagem(imagemSelecionada + 1)} disabled={imagemSelecionada === imagens.length-1}>
            <FaChevronRight size={25} color="#fff" />
          </BotaoNavegacao>

        </ContainerImagem>

        <ContainerLista>
          {imagens.map((imagem, index) => 
            <li> 
              <MiniaturaImagem 
                src={imagem} 
                selecionada={imagemSelecionada === index} 
                onClick={() => selecionarImagem(index)}
              />
            </li>
          )}
        </ContainerLista>
      </Container>
    </Overlay>
  )
}