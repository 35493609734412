import axios from 'axios';
import storage from '../storage/index';
import aux from '../utils/auxiliar';

const APIChamados = {

	getChamados: async function () {

		try {
			const header = aux.getHeaderPadrao();
			let result = null;
			const response = await axios.get(`${storage.getUrlClient()}/sacchamados`, { headers: header, timeout: 5000 });
			const { err, message } = aux.trataErroAPI(response);

			if (err) {
				console.log(message);
				return { err: true, mensagem: "Ops... Algo deu errado. Vamos Tentar novamente ?" };
			}

			const { chamados = [] } = response.data

			result = chamados.map(o => {
				o.data = aux.formataDataUTC(o.data, true);
				return o;
			});
			return { err: false, data: result }


		} catch (error) {
			return { err: true, mensagem: "Ops... Algo deu errado. Não conseguimos carregar seus chamados. Vamos tentar novamente?" };
		}

	},

	getAnexo: async function (anexo_id, chamado_id) {
		try {
			const header = aux.getHeaderPadrao();
			const body = { "chamado": chamado_id, "chaveAnexo": anexo_id }
			const response = await axios.post(`${storage.getUrlClient()}/SACAnexo`, body, { headers: header, timeout: 60000 });
			const { err, message } = aux.trataErroAPI(response)

			if (!err) {
				if (response.data.anexo !== "") return response
				else return null
			} else {
				console.log(message);
				return null;
			}

		} catch (error) {
			console.log(error)
		}
	},

	getAnexos: async function (chamado) {
		try {
			const header = aux.getHeaderPadrao();
			const response = await axios.get(`${storage.getUrlClient()}/SACAnexos/${chamado}`, { headers: header, timeout: 60000 });
			const { err, message } = aux.trataErroAPI(response);

			if (!err) {
				return response.data.anexos || []
			} else {
				console.log(message);
				return []
			}

		} catch (error) {
			console.log(error);
			return [];
		}
	},

	setAnexos: async function (files = [], chamado) {
		console.log(files);
		try {

			const header = aux.getHeaderPadrao();
			let anexosEnviados = 0;
			const qtAnexos = files.length;

			for (let i = 0; i < qtAnexos; i++) {
				try {
					const arq = files[i];

					const body = {
						"chamado": chamado,
						"conteudoArquivo": arq.document_id,
						"nomeOriginal": arq.fileName,
						"descricao": arq.description,
						"anexo": arq.file
					}

					const url = storage.getUrlClient();

					const response = await axios.post(`${url}/SACInclusaoAnexo`, body, { headers: header, timeout: 60000 });

					const { err, message } = aux.trataErroAPI(response);

					if (err) {
						console.log(message);
						return false;
					}
					else {
						anexosEnviados++;
					}

				} catch (error) {
					console.log(error);
					return false;
				}
			}

			if (anexosEnviados === qtAnexos) return true
			else return false;

		} catch (error) {
			console.log(error)
			return false;
		}
	},

	setChamado: async function ({ _ocorrencia, _titulo, _descricao, _filesToUpload }) {
		try {
			const body = {
				"prioridade": 2,
				"titulo": _titulo,
				"ocorrencia": _ocorrencia.key,
				"texto": _descricao,
			}

			const header = aux.getHeaderPadrao();
			const response = await axios.post(`${storage.getUrlClient()}/sacinclusao`, body, { headers: header });
			const { err, message } = aux.trataErroAPI(response);

			if (err) {
				console.log(message);
				return { err: true, mensagem: message };
			}

			if (_filesToUpload) {
				await this.setAnexos(_filesToUpload, response.data.chamado);
			}

			return { err: false, chamado: response.data.chamado };

		} catch (error) {
			console.log(error);
			return { err: true, mensagem: "Ops...algo deu errado. Vamos tentar novamente?" }
		}
	},

	getDetail: async function (chamado) {
		try {

			const header = aux.getHeaderPadrao();
			const response = await axios.get(`${storage.getUrlClient()}/sacchamado/${chamado}`, { headers: header });
			const { err, message } = aux.trataErroAPI(response);

			if (err) {
				console.log(message);
				return { err: true, mensagem: "Ops...algo deu errado. Vamos tentar novamente?" };
			}

			return { err: false, detail: response.data }

		} catch (error) {
			console.log(error);
			return { err: true, mensagem: "Ops...algo deu errado. Vamos tentar novamente?" };

		}
	},

	getOcorrencias: async function () {
		try {
			const header = aux.getHeaderPadrao();
			const response = await axios.get(`${storage.getUrlClient()}/SACListaOcorrencias`, { headers: header });
			const { data = [] } = response;
			const { err, message } = aux.trataErroAPI(response);

			if (err) {
				console.log(message);
				return []
			}

			let ocorrenciasSave = [];

			data.ocorrencias.forEach(item => {
				let aux = {
					key: item.ocorrencia,
					text: item.descricao,
					value: item.descricao,
					textoPadrao: item.textoPadrao,
					modeloChecklist: item.modeloChecklist
				}
				ocorrenciasSave.push(aux);
			});

			return ocorrenciasSave;

		} catch (error) {
			console.log(error);
		}
	},

	getTiposAnexo: async function () {

		const header = aux.getHeaderPadrao();
		const response = await axios.get(`${storage.getUrlClient()}/SACListaTipoAnexo`, { headers: header });
		const { data = [] } = response;
		const { err, message } = aux.trataErroAPI(response);

		if (err) {
			console.log(message);
			return [];
		}

		if (data.tipoanexo.length === 0) return [];

		let tiposAnexoSave = [];

		data.tipoanexo.forEach(item => {
			let aux = {
				key: item.tipo,
				text: item.descricao,
				value: item.descricao,
			}

			tiposAnexoSave.push(aux);
		});

		return tiposAnexoSave;
	}

}


export default APIChamados;


