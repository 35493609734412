import styled from "styled-components"

export const BackgroundModal = styled.div`
  top : 0;
  left:  0;
  display: flex;
  z-index: 909090;
  position: fixed;
  width:  100%;
  height:  100%;
  background : rgba(0,0,0,.4);
  justify-content: center;
  align-items: center;
`


export const BoxModal = styled.div`
  position: relative;
  background: #fff;
  border: none !important;
  border-radius: 5px;
  margin: 10px;
  width: 400px;
  max-height: 500px;
  height: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Quicksand';

  &:focus{
    outline: none !important;
  }


`

export const HeaderModal = styled.div`
  width: 100%;
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;

  position: relative;

  h1 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
  }

  button {
    background: none;
    border: none;
    position: absolute;
    right: 0;
  }
`

export const HeaderTextModal = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    font-size: ${props => props.theme.fontSize.normal};
    margin-left: 10px;
  }
`

