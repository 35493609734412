import { FaFileUpload, FaRegTrashAlt } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import { PiCaretUpDownFill } from "react-icons/pi";
import { TailSpin } from 'react-loader-spinner';
import BaseModal from "../../basemodal";
import OpcoesDropdown from "../../opcoesdropdown";
import { BodyModal } from "../style";
import ModalPesquisaMedico from "./modalpesquisamedico";
import { ArquivoAnexo, BotaoAtestado, BotaoEnviar, Dropdown, FormAtestado, Input, Label, Mock, Row } from "./styles";
import useAtestado from "./useAtestado";

export default function Atestado(props){
  const {
    valoresIniciais,
    valores,
    configuracaoCampos,
    tiposOcorrencia,
    opcoesTipoOcorrenciaAberto,
    opcoesTipoCRMAberto,
    modalHospitalAberto,
    categoriaOcorrencia,
    nomeHospital,
    anexos,
    statusEnvioAnexos,
    erroAnexos,
    camposObrigatoriosPorTipo,
    carregando,
    enviandoAtestado,
    buscandoMedico,
    ultimoCRMDigitado,
    abrirOpcoesTipoOcorrencia,
    fecharOpcoesTipoOcorrencia,
    abrirOpcoesTipoCRM,
    fecharOpcoesTipoCRM,
    abrirModalHospital,
    fecharModalHospital,
    selecionarHospital,
    alterarValor,
    anexarArquivos,
    desanexarArquivosEnviados,
    excluirArquivosAnexos,
    buscarMedicoPorCRM,
    aoFocarCRM,
    submeterAtestado,
    tiposCRM,
    tipoOcorrenciaSelecionada,
    tipoCRMSelecionado,
    camposObrigatorios,
    nomesCamposObrigatorios,
    todosCamposObrigatoriosPreenchidos,
    todosValoresValidos
  } = useAtestado(props)



  if (opcoesTipoOcorrenciaAberto) return <OpcoesDropdown opcoes={tiposOcorrencia} aoEscolher={(valor) => alterarValor(valor, 'tipoOcorrencia')} fecharModal={fecharOpcoesTipoOcorrencia} />
  if (opcoesTipoCRMAberto) return <OpcoesDropdown opcoes={tiposCRM} aoEscolher={(valor) => alterarValor(valor, 'tipoCRM')} fecharModal={fecharOpcoesTipoCRM} />
  if (modalHospitalAberto) return <ModalPesquisaMedico aoEscolher={selecionarHospital} fecharModal={fecharModalHospital} />


  if(carregando) return (
    <BaseModal>
      <BodyModal>
        <Mock count={3} />
      </BodyModal>
    </BaseModal>
  )

  return (
    <BaseModal titulo={"Envio de atestado"} fecharModal={props.fecharModal}>

      <BodyModal>

        <FormAtestado>
          
          <Label>Tipo de ocorrência *</Label>
          <Dropdown onClick={abrirOpcoesTipoOcorrencia}>
            <span>{tipoOcorrenciaSelecionada ? tipoOcorrenciaSelecionada.label : "Selecione o tipo"}</span>
            <PiCaretUpDownFill />
          </Dropdown>


          <Label htmlFor="descricao">Descrição *</Label>
          <Input id="descricao" placeholder="Digite a descrição" value={valores.descricao} onChange={e => alterarValor(e.target.value, 'descricao')}/>


          <Row>
            <div>
              <Label htmlFor="data_inicio">Data de início *</Label>
              <Input id="data_inicio" type="date" value={valores.dataInicial} onChange={e => alterarValor(e.target.value, 'dataInicial')} max={valores.dataFinal}/>
            </div>


            <div>
              <Label htmlFor="data_fim">Data de fim *</Label>
              <Input id="data_fim" type="date" value={valores.dataFinal} onChange={e => alterarValor(e.target.value, 'dataFinal')} min={valores.dataInicial}/>
            </div>
          </Row>


          {categoriaOcorrencia === 1 && <>

            {configuracaoCampos.find(item => item.nome === 'hospital')?.visivel && <>

              <Label>{`Hospital ${nomesCamposObrigatorios.includes('hospital') ? `*` : ''} `}</Label>
              <Dropdown onClick={abrirModalHospital}>
                <span>{valores['relHospital'] ? nomeHospital : "Pesquisar hospital"}</span>
                <PiCaretUpDownFill />
              </Dropdown>
            </>
            }



            {configuracaoCampos.find(item => item.nome === 'crm')?.visivel && <>
              <Label>{`Tipo CRM ${nomesCamposObrigatorios.includes('crm') ? `*` : ''} `}</Label>
              <Dropdown onClick={abrirOpcoesTipoCRM}>
                <span>{tipoCRMSelecionado ? tipoCRMSelecionado.label : "Selecione o tipo"}</span>
                <PiCaretUpDownFill />
              </Dropdown>

              <Label htmlFor="crm">{`CRM ${nomesCamposObrigatorios.includes('crm') ? `*` : ''} `}</Label>
              <Input id="crm" placeholder="Digite o CRM" value={valores.crm} onChange={e => alterarValor(e.target.value, 'crm')} onBlur={buscarMedicoPorCRM} onFocus={aoFocarCRM} disabled={valores.tipoCRM === 0}/>
            </>
            }


           

            {configuracaoCampos.find(item => item.nome === 'medico')?.visivel && <>
              <Label htmlFor="medico">{`Médico ${nomesCamposObrigatorios.includes('medico') ? `*` : ''} `}</Label>
              {buscandoMedico ? <Mock count={1}/> :<Input id="medico" placeholder="Digite o nome do médico" value={valores.medico} onChange={e => alterarValor(e.target.value, 'medico')} />}
            </>
            }



            {configuracaoCampos.find(item => item.nome === 'cid')?.visivel && <>
              <Label htmlFor="cid">{`CID ${nomesCamposObrigatorios.includes('cid') ? `*` : ''} `}</Label>
              <Input id="cid" placeholder="Digite o CID" value={valores.cid} onChange={e => alterarValor(e.target.value, 'cid')}/>
            </>
            }

          </> 
          }


          {categoriaOcorrencia === 2 && <>
            {configuracaoCampos.find(item => item.nome === 'orgao')?.visivel && <>
              <Label htmlFor="orgao">{`Órgão ${nomesCamposObrigatorios.includes('orgao') ? `*` : ''} `}</Label>
              <Input id="orgao" placeholder="Digite o órgão responsável" value={valores.orgao} onChange={e => alterarValor(e.target.value, 'orgao')}/>  
            </>} 
          </>}


          <BotaoAtestado htmlFor="anexo">
            <FaFileUpload style={{ marginRight: 5 }}/>
            Anexar Atestado *
          </BotaoAtestado>
          <input id='anexo' type="file" style={{ display: 'none'}} onChange={anexarArquivos} multiple/>

          {erroAnexos && <span>{erroAnexos}, <a onClick={desanexarArquivosEnviados}>deseja tentar novamente?</a></span>}

          {anexos.length > 0 && !erroAnexos &&
            <ul style={{ padding: 0, marginTop: 10 }}>
              {anexos.map((item, index) => 
                <ArquivoAnexo key={index}>
                  {item.nome}
                  
                  {statusEnvioAnexos[index] === undefined &&
                    <button onClick={() => excluirArquivosAnexos(index)}>
                      <FaRegTrashAlt />
                    </button>
                  }

                  {statusEnvioAnexos[index] === false && <IoCloseOutline color="#d90429"/>}
                  {statusEnvioAnexos[index] && <FaCheck color="#73BFB8"/>}
                </ArquivoAnexo>
              )}
            </ul>
          }

      

        <BotaoEnviar type="submit" disabled={!todosValoresValidos} onClick={submeterAtestado}>
          {enviandoAtestado ? <TailSpin width={20} height={20} color={'#fff'}  /> : 'Enviar'}
        </BotaoEnviar>

      </FormAtestado>
      </BodyModal>
    </BaseModal>
  )
}