import Axios from "axios";
import aux from "../utils/auxiliar";
import storage from "../storage";

const APIAtestado = {
  obterTiposOcorrencia: async function () {

    try {
      const headers = aux.getHeaderPadrao();
      const url = storage.getUrlClient();


      const resposta = await Axios.get(`${url}/OcorrDisciplinaTiposOcorrenciasApp`, { headers });

      const { erro, mensagem } = aux.trataErroAPI(resposta);

      if (erro) {
        return { errorMessage: mensagem }
      }

      return resposta.data.tiposOcorrencia;

    } catch (e){
      console.log(e)
      return { errorMessage: true }
    }
  },

  buscarHospitalPorNome: async function (name) {

    const tokenPlusLicenca = storage.getTokenLoginPlusLicenca()
    const urlClient = storage.getUrlClient();

    try {
      const header = {
        'Authorization': `Bearer ${tokenPlusLicenca}`,
        'Content-Type': 'application/json'
      };

      const response = await Axios.get(`${urlClient}/BuscarHospitais/${name}`, { headers: header, timeout: 60000 });


      const result = aux.trataErroAPI(response)
      if (!result.err) {
        return {
          error: false,
          data: response.data.hospitais
        }
      } else {
        return { error: true, message: result.message }
      }
    } catch (error) {
      return { error: true, message: 'Algo deu errado...' };
    }
  },


  obterSetUpAtestado: async function (body) {
    try {
      const headers = aux.getHeaderPadrao();
      const url = storage.getUrlClient();

      const response = await Axios.post(`${url}/InclusaoOcorrDisciplinarApp`, body, { headers });

      const { err, message } = aux.trataErroAPI(response);

      if (err) {
        return { errorMessage: response.data.mensagem }
      }

      const docPatternResponse = await Axios.get(`${url}/OcorrDisciplinarPadraoAtestado`, { headers });

      const { docPatternErr, docPatternMessage } = aux.trataErroAPI(docPatternResponse);


      if (docPatternErr) {
        return { errorMessage: docPatternResponse.data.mensagem }
      }

      const setUp = {
        histdisciplinar: response.data.histDisciplinar,
        conteudoArquivo: docPatternResponse.data.conteudoArquivo,
        errorMessage: false
      }

      return setUp;

    } catch (error) {
      return { errorMessage: `Erro [obterSetUpAtestado]: ${error}` };
    }
  },

  enviarAtestado: async function (body) {

    try {
      const headers = aux.getHeaderPadrao();
      const url = storage.getUrlClient();


      const response = await Axios.post(`${url}/OcorrDisciplinarInclusaoAnexo`, body, { headers });


      if (response.data.status !== 0) return { errorMessage: response.data.mensagem }


      return true;

    } catch {
      return { errorMessage: true }
    }


  },

  obterCamposAtestado: async function () {

    try {
      const headers =  aux.getHeaderPadrao();
      const url = storage.getUrlClient();


      const resposta = await Axios.get(`${url}/InfoOcorrDisciplina`, { headers });

      const { erro, mensagem } = aux.trataErroAPI(resposta);

      if (erro) {
        return { errorMessage: mensagem }
      }

      return resposta.data.controlaCampos;

    } catch {
      return { errorMessage: true }
    }
  },

  buscarMedicoPorCRM: async function (crm, tpcrm) {
    try {
      const headers = aux.getHeaderPadrao();
      const url = storage.getUrlClient();


      const resposta = await Axios.post(`${url}/BuscaMedicoCRM`, { crm: Number(crm), tpcrm }, { headers });

      const { erro, mensagem } = aux.trataErroAPI(resposta);

      if (erro) {
        return { error: true, message: mensagem }
      }

      return {
        error: false,
        data: resposta.data.medicos[0]
      }

    } catch (error) {
      return { error: true, message: `Erro buscaMedicoByCrm: ${error}` }
    }
  }
}

export default APIAtestado;