import styled, { css, keyframes } from "styled-components";
import principal from "../../theme/principal";



const slideDownKeyframes = keyframes`
  from{
    transform: translateY(-25px);
  }to{
    transform: translateY(0);
  }
`




export const slideDown = css`
  animation: ${slideDownKeyframes} .2s ease-out forwards;
  z-index: 99;
  position: relative;
`





export const Container = styled.div`
  width: 100%;
  padding: 10px 20px;
  position: relative;
`


export const Titulo = styled.h1`
  font-size: 22px;
  margin-bottom: 25px;
`

export const Pergunta = styled.span`
  z-index: 999;
  position: relative;
`

export const ContainerAlternativas = styled.ul`
  padding: 0;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
`

export const Mensagem = styled.div`
  font-size: 12px;
  color: ${principal.colors.red};
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ContainerAnimacao = styled.div`
  ${props => props.ativo && slideDown}
`

export const Alternativa = styled.button`
  background: ${principal.colors.background};
  border: none;
  width: 100%;
  padding: 5px 10px;
  text-align: left;
  border-radius: 4px;

  ${props => props.selecionada && `
    background: ${principal.colors.primary};
    color: #fff;
  `}
`

export const Footer = styled.div`
  width: 100%;
  margin-top: 75px;
`


export const InputTexto = styled.textarea`
  width: 100%;
  background: ${principal.colors.background};
  border: none;
  border-radius: 4px;
  resize: none;
  padding: 10px;

  ${props => props.disabled && `
    opacity: .3;
    background: #fff;
  `}


  ${props => props.erro && `
    border: 1px solid ${principal.colors.red};
  `}
`


export const ContainerOpcoes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`

export const ContainerNavegacao = styled.div`
  display: flex;
  gap: 20px;
`

export const BotaoNavegacao = styled.button`
  background: none;
  border: none;
  font-weight: bold;
  padding: 0;
  ${props => props.cor && `color: ${props.cor}`}
`


export const BotaoAnexo = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
`


export const IndicadorQtdAnexos = styled.div`
  background: ${props => props.erro ? principal.colors.red : principal.colors.primary}
  border-radius: 50%;
  width: 15px;
  height: 15px;
  color: #fff;
  position: absolute;
  top:-10px;
  right:-10px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  

`


