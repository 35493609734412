import aux from "../../../utils/auxiliar";
import BaseModal from "../../basemodal";
import { ListaOpcoes } from "./styles";

export default function OpcoesHorario({ opcoes, fecharModal, abrirDemonstrativo }){
  const dicionarioMarcacao = {
    entrada: "Entrada",
    saidaAlmoco: "Saída Almoço",
    retornoAlmoco: "Retorno Almoço",
    saida: "Saída"
  }


  return(
    <BaseModal fecharModal={fecharModal}>
      <ListaOpcoes>
        {opcoes.map((opcao, index) => <li onClick={() => abrirDemonstrativo(opcao.marcacao)} key={index}>{dicionarioMarcacao[opcao.marcacao]} - {aux.extraiHorario(opcao.horario)}</li>)}
      </ListaOpcoes>
    </BaseModal>
  )
}