import { useCallback, useEffect, useState } from "react";
import APIAtestado from "../../../../API/APIAtestado";
import BaseModal from "../../../basemodal";
import { Input } from "../styles";
import { Container, Item, Lista } from "./styles";
import { debounce } from "lodash";
import Loading from "../../../loading/Loading";
import Spinner from "../../../spinner/Spinner";

export default function ModalPesquisaMedico({ aoEscolher, fecharModal }){
  const [hospitais, setHospitais] = useState([]);
  const [query, setQuery] = useState('');
  const [carregando, setCarregando] = useState();


  async function buscarDados(query) {
    try {
      const response = await APIAtestado.buscarHospitalPorNome(query);
      if (response.error) return setHospitais([])
        setHospitais(response.data.length > 0 ? response.data : [])
    } catch (error) {
      throw new Error(`fetchData: ${error}`)
    } finally {
      setCarregando(false)
    }
  }



  const debouncedFetchResults = useCallback(debounce((query) => {
    buscarDados(query);
  }, 500), []);



  useEffect(() => {
    debouncedFetchResults(query); 
  }, [query, debouncedFetchResults]);




  useEffect(() => {
    if (!carregando) setCarregando(true);
  }, [query])



  function aoClicar(nome, id){
    aoEscolher(nome, id);
    fecharModal();
  }


  return (
    <BaseModal fecharModal={fecharModal}>
      <Container>
        <Input placeholder="Digite o nome do hospital ou clínica" style={{ width: '100%', marginBottom: 5 }} onChange={e => setQuery(e.target.value)}/>

        {carregando && <div style={{ marginTop: 40 }}><Spinner color={"#000"} /></div>}
        {hospitais.length === 0 && !carregando && <span style={{ marginTop: 10 }}>Nenhum resultado encontrado</span>}

        {hospitais.length > 0 && !carregando &&
          <Lista>
            {hospitais.map(item => <Item onClick={() => aoClicar(item.nome, item.relacaoEntidade)} key={item.relacaoEntidade}>{item.nome}</Item>)}
          </Lista>
        }
      </Container>
    </BaseModal>
  )
}