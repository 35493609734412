export default {
  colors: {
    primary: "#2991D6",
    green: "#2FAF9F",
    red: "#DC3545",
    background: '#eef0f2'
  },
  font: "Quicksand",
  fontSize: {
    extrasmall: '1rem',
    small: '1.2rem',
    smallernormal: '1.4rem',
    normal: '1.6rem',
    medium: '1.75rem',
    large: '2.2rem',
    extralarge: '2.4rem',
    huge: '3.2rem',
    extrahuge: '3.6rem',
    max: '4.8rem'
  }
}