import styled from "styled-components";

export const ListaOpcoes = styled.ul`
  width: 100%;
  padding: 10px;

  li {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #eee;
    padding: 5px;
    cursor: pointer;
  }

`