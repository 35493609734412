import React from 'react';
import aux from "./../../utils/auxiliar";
import APIAuth from "./../../API/APIAuth";
import storage from "../../storage"
import Loading from "./../../components/loading/Loading"
import { callAnalytics } from "./../../utils/analytics"
import {
	withRouter,
	Redirect
} from "react-router-dom";


const RequireAuth = (Component, rota, routeName) => {

	return withRouter(class App extends React.Component {
		state = {
			isAuthenticated: false,
			isLoading: true,
			rotaValida: false
		}

		deslogaApp = () => {
			const body = {
				acao: "LogoutApp"
			}
			window.ReactNativeWebView.postMessage(JSON.stringify(body));
		}

		async componentDidMount() {

			// Vamnos chamar o Analytcs para indicar qual página estamos
			callAnalytics(routeName);

			// Primeira parte vamos verificar qualquer um dos dois tokens estão expirados;
			try {
				const isTokenAcesso = aux.isTokenAcessoExpired();
				const isTokenLicencaExpired = aux.isTokenLicencaExpired();

				if (isTokenAcesso || isTokenLicencaExpired) {

					//Na melhor das hipoteses, pedir os dois.
					const responseTokenLicenca = await APIAuth.getNewTokenLicenca();
					const responseTokenAcesso = await APIAuth.getNewTokenAcesso();

					if (responseTokenLicenca !== 200 || responseTokenAcesso !== 200) {
						if (storage.isMobile()) {
							this.deslogaApp();
							return
						}
						this.setState({ isAuthenticated: false, isLoading: false })
					} else {
						this.setState({ isAuthenticated: true, isLoading: false })
					}


				} else {

					const rotaValida = await APIAuth.isValidRoute(rota);

					if (!rotaValida && rota !== 99999) {
						if (storage.isMobile()) {
							this.deslogaApp();
							return;
						}
						this.props.history.goBack();
						return;
					}

					this.setState({ isAuthenticated: true, isLoading: false, rotaValida })

				}
			} catch (error) {
				alert(error)
				console.log(error);
			}


		}


		render() {
			const { isAuthenticated, isLoading } = this.state;
			if (isLoading) {
				return <Loading show={true} />
			}
			if (!isAuthenticated) {
				return <Redirect to="/" />
			}
			return <Component {...this.props} />
		}
	})

}

export { RequireAuth }