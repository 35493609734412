import { BackgroundModal, BoxModal, HeaderModal } from "./style";
import { IoClose } from "react-icons/io5";

export default function BaseModal({ children, titulo, fecharModal, boxSize, ...props }){
  return(
    <BackgroundModal {...props}>
      <BoxModal size={boxSize}>
        <HeaderModal>
          <h1>{titulo}</h1>
          <button onClick={fecharModal} style={{cursor: 'pointer'}}>
            <IoClose/>
          </button>
        </HeaderModal>

        {children}

      </BoxModal>
    </BackgroundModal>
  )
}