import styled from "styled-components";
import principal from "../../theme/principal";

export const Overlay = styled.div`
  top: 0;
  left: 0;
  display: flex;
  z-index: 9999999;
  position: fixed;
  width:  100%;
  height:  100%;
  background : rgba(0,0,0,.4);
  justify-content: center;
  align-items: center;
  padding: 20px;
`


export const BotaoFechar = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
`


export const ContainerImagem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  img {
    max-height: 75vh;
  }
`


export const BotaoNavegacao = styled.button`
  border: none;
  background: none;

  ${props => props.disabled && `
    opacity: .1;
  `}
`

export const ContainerLista = styled.ul`
  height: 100px;
  display: flex;
  gap: 5px;
  padding:0;
`


export const MiniaturaImagem = styled.img`
  width: 100px;
  height: 100px;
  cursor: pointer;

  ${props => props.selecionada && `
    border: 2px solid ${principal.colors.primary}
  `}
`