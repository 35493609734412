'use-strict'

import React, { Component } from 'react';
import CardChamado from '../../components/cardchamado/CardChamado';
import APIChamados from '../../API/APIChamados';
import storage from '../../storage/index';
import { Tab } from 'semantic-ui-react';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { Modal } from '@material-ui/core';
import { Form, Icon } from 'semantic-ui-react';
import Loading from '../../components/loading/Loading';
import ChamadoDetail from '../../components/chamadoDetail/ChamadoDetail';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Alert from '../../components/alert/Alert';
import aux from "../../utils/auxiliar";
import ButtonFile from '../../components/buttonfile/ButtonFile';
import ListChamadosMobile from '../../components/listchamadosmobile/ListChamadosMobile';
import BotaoIncluir from "./../../components/botaoincluir/BotaoIncluir";
import AddIcon from '@material-ui/icons/Add';
import InsertAttachment from "../../components/insertattachment";

import { 
	Container, 
	DescriptionInput, 
	FileButton, 
	FloatingButtonContainer, 
	IconButton, 
	InputContainer, 
	InputMock, 
	ListContainer, 
	ListHeader, 
	ListTab, 
	ModalBody, 
	ModalBox, 
	ModalButton, 
	ModalFooter, 
	ModalHeader, 
	ModalOverlay, 
	StatusFilterContainer 
} from './style';

import {
	Dropdown,  
	SubTitle 
} from '../../global/style';
import Checklist from '../../components/checklist';
import BaseModal from '../../components/basemodal';

import { FaRegImages, FaRegEdit } from "react-icons/fa";
import Galeria from '../../components/galeria';


export default class Sac extends Component {


	constructor(props) {
		super(props);


		this.state = {
			chamados: [],
			pendentes: [],
			concluidos: [],
			copyPendentes: [],
			copyConcluidos: [],
			qtConcluidos: 0,
			qtPendentes: 0,
			open: false,
			teste: '',
			ocorrencias: [],
			_ocorrencia: { key: 0, texto: "" },
			_titulo: '',
			_descricao: '',
			showValidate: false,
			loading: true,
			openDetail: false,
			chamadoAtual: {},
			windowW: 0,
			alertMessage: '',
			show: false,
			_filesToUpload: [],
			openAnother: false,
			tiposAnexo: [],
			activeIndex: 0,
			place: true,
			isMobile: storage.isMobile(),
			anexosToDetail: [],
			textoPadraoOcorrencia: null
		};

	}

	componentDidMount() {
		aux.answerAPP();
		this.updateChamadosList();
		window.addEventListener("resize", this.updateDimensions);
		this.updateDimensions();
	}


	componentWillMount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	showMessage = (message) => this.setState({ alertMessage: message, show: true });

	closeMessage = () => this.setState({ show: false });

	updateDimensions = () => {
		const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		this.setState({ windowW: w });
	}

	activeLoading = (estado) => {
		this.setState({ loading: estado });
	}

	updateChamadosList = async () => {
		try {

			this.activeLoading(true);

			const responseChamados = await APIChamados.getChamados();
			const responseOcorrencias = await APIChamados.getOcorrencias();

			storage.setOcorrencias(responseOcorrencias);

			const tiposAnexo = await APIChamados.getTiposAnexo();
			storage.setTiposAnexo(tiposAnexo);

			let concluidos = 0;
			let pendentes = 0;

			let chamados = [];
			let ocorrencias = [];

			if (!responseChamados.err) {
				chamados = responseChamados.data;
				concluidos = chamados.filter(o => o.situacao === 'CONCLUÍDO');
				pendentes = chamados.filter(o => o.situacao === 'PENDENTE');
			} else {
				this.activeLoading(false);
				this.showMessage(responseChamados.mensagem)
				return;
			}

			if (responseOcorrencias) ocorrencias = responseOcorrencias;


			this.setState({
				chamados: responseChamados.data,
				qtConcluidos: concluidos.length,
				qtPendentes: pendentes.length,
				ocorrencias: ocorrencias,
				tiposAnexo: tiposAnexo,
				pendentes, copyPendentes: pendentes,
				concluidos, copyConcluidos: concluidos
			});

			this.activeLoading(false);
		} catch (error) {
			this.activeLoading(false);
		}

	}


	handleClose = () => {
		this.setState({ open: false, showValidate: false })
	}


	opeNew = () => {
		this.setState({
			open: true,
			_descricao: '',
			_ocorrencia: { key: 0, texto: "" },
			_titulo: '',
			showValidate: false,
			_filesToUpload: []
		});
	}

	showMessage = (mensagem) => {
		this.setState({
			show: true, alertMessage: mensagem
		});
	}

	handleChange = (e) => {
		this.setState({ teste: e.target.value });
	}

	cancelarAbertura = () => {
		this.setState({ open: false });
	}

	handleSelectOcorrencia = (e, data) => {
		
		const { key, textoPadrao, modeloChecklist } = data.options.filter(o => o.value === data.value)[0]

		if(modeloChecklist){
			this.setState({ mostrarInputChecklist: true, modeloChecklist })
		}

		if(this.state.mostrarInputChecklist) this.setState({ mostrarInputChecklist: false, modeloChecklist: 0 })

		let auxTextoPadrao = "";

		if (textoPadrao) {
			auxTextoPadrao = textoPadrao
		}

		this.setState({ _ocorrencia: { key: key, texto: e.target.textContent }, placeHolderPadrao: auxTextoPadrao, _descricao: modeloChecklist ? '' : auxTextoPadrao });
	}



	handleTitulo = (e) => {
		this.setState({ _titulo: e.target.value });
	}

	handleDescricao = (e) => {
		this.setState({ _descricao: e.target.value });
	}

	isValidou = () => {
		let result = true;
		const { _ocorrencia, _descricao, _titulo } = this.state;
		if (_ocorrencia.key === 0 || _descricao === "" | _titulo === "") {
			result = false;
			this.setState({ showValidate: true })
		} else {
			result = true;
			this.setState({ showValidate: false })
		}

		return result;
	}

	gravaChamado = async () => {
		try {
			this.activeLoading(true);
			if (this.isValidou()) {
				const response = await APIChamados.setChamado(this.state);

				if (!response.err) {
					await this.updateChamadosList();
					this.handleClose();
				} else {
					this.showMessage("Ops... algo deu errado. Vamos tentar novamente?");
				}
			}
			this.activeLoading(false);
		} catch (error) {
			this.showMessage("Ops... algo deu errado. Vamos tentar novamente?");
			this.activeLoading(false);
		}

	}



	openDetail = async (chamado) => {
		this.setState({ openDetail: true, chamadoAtual: chamado });
		// try {

		// 	this.activeLoading(true);
		// 	const response = await APIChamados.getDetail(chamado);

		// 	const anexos = await APIChamados.getAnexos(chamado);

		// 	if (!response.err) {
		// 		this.setState({ openDetail: true, chamadoAtual: response.detail, anexosToDetail: anexos});
		// 	} else {
		// 		this.showMessage('Ops... algo deu errado. Vamos tentar novamente?');
		// 	}
		// 	this.activeLoading(false);
		// } catch (error) {
		// 	this.activeLoading(false);
		// }

	}

	closeDetail = () => {
		this.setState({ openDetail: false, chamadoAtual: {}, number_chamado: 0 });
	}

	getClassSit = (row) => {
		if (row === 'PENDENTE') return 'classPendente'
		else if (row === 'CONCLUÍDO') return 'classConcluido'
	}



	clickFileUpload = () => {
		this.setState({ openAnother: true });

	}


	handleCloseAnother = () => {
		this.setState({ openAnother: false })
	}

	addFileToUpload = async (list, nameCurrentAnexo, catCurrentAnexo) => {
		let files = [];

		for (let i = 0; i < list.length; i++) {
			try {
				this.activeLoading(true);
				files.push({
					fileName: list[i].name,
					file: await aux.getBase64(list[i]),
					description: nameCurrentAnexo,
					document_id: catCurrentAnexo.key,
					type: aux.getTypeFile(list[i].name)
				});
				this.activeLoading(false);
			} catch (error) {
				this.activeLoading(false);
			}
		}

		this.setState({ _filesToUpload: this.state._filesToUpload.concat(files) });
	}

	handleFileUpload = async (e, nameCurrentAnexo, catCurrentAnexo) => {
		this.handleCloseAnother();
		this.addFileToUpload(e.target.files, nameCurrentAnexo, catCurrentAnexo);
	}

	excludItem = (index) => {
		let itensAtuais = this.state._filesToUpload.map(o => o);
		itensAtuais.splice(index, 1);
		this.setState({ _filesToUpload: itensAtuais });
	}

	filterListPendentes = (searchText) => {
		let result = [];
		let text = searchText;

		if (text === "") {
			this.setState({ pendentes: this.state.copyPendentes });
			return;
		}

		this.state.copyPendentes.forEach(item => {

			if (item.ocorrencia.toUpperCase().indexOf(text) > -1 || item.acao.toUpperCase().indexOf(text) > -1 || item.numeroChamado.toString().indexOf(text) > -1 || item.data.toUpperCase().indexOf(text) > -1 || item.titulo.toUpperCase().indexOf(text) > -1) {
				result.push(item);
			}
		});

		this.setState({ pendentes: result });

	}

	filterListConcluidos = (searchText) => {
		let result = [];
		let text = searchText;

		if (text === "") {
			this.setState({ pendentes: this.state.copyConcluidos });
			return;
		}

		this.state.copyConcluidos.forEach(item => {

			if (item.ocorrencia.toUpperCase().indexOf(text) > -1 || item.acao.toUpperCase().indexOf(text) > -1 || item.numeroChamado.toString().indexOf(text) > -1 || item.data.toUpperCase().indexOf(text) > -1 || item.titulo.toUpperCase().indexOf(text) > -1) {
				result.push(item);
			}
		});

		this.setState({ concluidos: result });

	}

	goPendentes = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
		this.setState({ activeIndex: 0 });
	}

	goConluidos = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
		this.setState({ activeIndex: 1 });
	}


	abrirChecklist = () => {
		this.setState({ mostrarChecklist: true });
	}


	fecharChecklist = () => {
		this.setState({ mostrarChecklist: false });
	}



	finalizarChecklist = (texto, imagens) => {	
		this.setState({ _descricao: texto, imagensChecklist: imagens, mostrarChecklist: false })
	}


	abrirGaleria = () => {
		this.setState({ mostrarGaleria: true })
	}


	fecharGaleria = () => {
		this.setState({ mostrarGaleria: false })
	}

	gravaChamadoComChecklist = async () => {
		try {
			this.activeLoading(true);
      const result = await APIChamados.setChamado(this.state);

      if (result.err) {
        this.showMessage(result.mensagem)
        return;
      }

      const responseTiposAnexo = await APIChamados.getTiposAnexo();

		
			const bodyArray = this.state.imagensChecklist.map(item => {
				let fileName = `image${Date.now()}`;
				return {
					document_id: responseTiposAnexo[0].key,
					fileName,
					description: `ImagemChecklist${Date.now()}`,
					file: item
				}
			})

      let filesResult;
			filesResult = await APIChamados.setAnexos(bodyArray, result.chamado)

			if (filesResult.err) {
				this.showMessage(filesResult.mensagem);
				return;
			}

      

      this.showMessage('SAC enviado');

      this.updateChamadosList();
      this.handleClose()
    } catch (error) {
			console.log(error);
      this.showMessage('Algo deu errado... tente novamente ' + error)
    } finally {
      setTimeout(() => {
        this.activeLoading(false);
      }, 2000)

    }
	}


	render() {

		const openDetail = this.openDetail;
		const options = {
			page: 1,
			onRowClick: function (row) {
				openDetail(row.chamado)
			},
			hideSizePerPage: true,
			withoutNoDataText: false,
			noDataText: 'nenhum chamado encontrado'
		};

		let panes = [];

		if (this.state.windowW > 800) {
			panes = [
				{
					menuItem: { key: 'Pendentes', icon: 'meh outline', content: 'Pendentes', className: "btnPendentes" },
					render: () =>
						<BootstrapTable
							tableStyle={{ border: '#fff 0px solid', fontSize: '12px' }}
							containerStyle={{ border: '#000 0px solid' }}
							headerStyle={{ border: '#000 0px solid' }}
							bodyStyle={{ border: '#000 0px solid' }}
							trClassName="tableChamados" options={options} search={true} searchPlaceholder='Pesquisar...' data={this.state.pendentes} striped hover pagination >
							<TableHeaderColumn hidden={this.state.windowW < 800} dataField='acao'>Ação</TableHeaderColumn>
							<TableHeaderColumn dataField='ocorrencia'>Ocorrencia</TableHeaderColumn>
							<TableHeaderColumn dataField='titulo'>Título</TableHeaderColumn>
							<TableHeaderColumn hidden={this.state.windowW < 800} tdStyle={{background: '#dc3545', color: '#fff'}} dataField='situacao'>Situação</TableHeaderColumn>
							<TableHeaderColumn hidden={this.state.windowW < 800} dataField='numeroChamado' isKey>Numero</TableHeaderColumn>
							<TableHeaderColumn hidden={this.state.windowW < 800} dataField='data' dataSort={true}>Data</TableHeaderColumn>
						</BootstrapTable>,
				},
				{
					menuItem: { key: 'concluidos', icon: 'thumbs up', content: 'Concluidos' },
					render: () =>
						<BootstrapTable
							tableStyle={{ border: '#fff 0px solid', fontSize: '12px' }}
							containerStyle={{ border: '#000 0px solid' }}
							headerStyle={{ border: '#000 0px solid' }}
							bodyStyle={{ border: '#000 0px solid' }}
							trClassName="tableChamados" options={options} search={true} searchPlaceholder='Pesquisar...' data={this.state.concluidos} striped hover pagination >
							<TableHeaderColumn hidden={this.state.windowW < 800} dataField='acao'>Ação</TableHeaderColumn>
							<TableHeaderColumn dataField='ocorrencia'>Ocorrencia</TableHeaderColumn>
							<TableHeaderColumn dataField='titulo'>Título</TableHeaderColumn>
							<TableHeaderColumn hidden={this.state.windowW < 800} tdStyle={{background: '#2991d6', color: '#fff'}} dataField='situacao' dataSort={true}>Situação</TableHeaderColumn>
							<TableHeaderColumn hidden={this.state.windowW < 800} dataField='numeroChamado' isKey>Numero</TableHeaderColumn>
							<TableHeaderColumn hidden={this.state.windowW < 800} dataField='data' dataSort={true}>Data</TableHeaderColumn>
						</BootstrapTable>,
				},
			]
		} else {
			panes = [

				{
					menuItem: { key: 'Pendentes', icon: 'meh outline', content: 'Pendentes', className: "btnPendentes" },
					render: () => <ListChamadosMobile colorLateral="var(--vermelho)" data={this.state.pendentes} openDetail={this.openDetail} filterList={this.filterListPendentes} />

				},
				{
					menuItem: { key: 'concluidos', icon: 'thumbs up', content: 'Concluidos' },
					render: () => <ListChamadosMobile colorLateral="var(--azul)" data={this.state.concluidos} openDetail={this.openDetail} filterList={this.filterListConcluidos} />
				}

			];
		}


		return (

			<div className={`containerChamados ${this.state.windowW > 800 ? "" : "container-mobile"}`}>
				<Alert
					show={this.state.show}
					title=""
					message={this.state.alertMessage}
					onClose={this.closeMessage}
				/>

				<InsertAttachment
					show={this.state.openAnother}
					close={this.handleCloseAnother}
					getFileUpload={this.handleFileUpload}
					typesOfAttachment={this.state.tiposAnexo}
				/>

				<ChamadoDetail
					open={this.state.openDetail}
					close={this.closeDetail}
					chamado={this.state.chamadoAtual}
					anexos={this.state.anexosToDetail}
					newAnexo={this.clickFileUpload}
					typesOfAttachment={this.state.tiposAnexo}
					updateListOfAttachment={this.updateListOfAttachment}
				/>

				<Loading show={this.state.loading} />
				{
					this.state.windowW < 1024 && !this.state.open ? (
						<FloatingButtonContainer>
							<Fab color="secondary" aria-label="Add" style={{background: "rgba(50,57,59, .6)"}} onClick={this.opeNew}>
								<AddIcon />
							</Fab>
						</FloatingButtonContainer>
					) : ""
				}

				{this.state.mostrarChecklist && 
					<Checklist 
						modeloID={this.state.modeloChecklist} 
						fechar={this.fecharChecklist} 
						finalizar={this.finalizarChecklist}
					/>
				}

				{this.state.mostrarGaleria &&
					<Galeria imagens={this.state.imagensChecklist} fechar={this.fecharGaleria}/> 
				}
			
				<Container>
					{this.state.open && <BaseModal boxSize={'720px'} titulo={'Abertura de chamado'} fecharModal={this.cancelarAbertura}>
						
						<ModalBody>
							<Form>
								<InputContainer widths='equal'>
									<Dropdown label='Ocorrência *' options={this.state.ocorrencias} onChange={this.handleSelectOcorrencia} placeholder='Ocorrências' />
								</InputContainer>

								{
									this.state.showValidate && this.state._ocorrencia.key === 0 ? (<span className="reqCampo">campo requerido</span>) : ''
								}

								<InputContainer widths='equal'>
									<Form.Input fluid label='Título *' value={this.state._titulo} onChange={this.handleTitulo} placeholder='Título' />
								</InputContainer>


								{
									this.state.showValidate && this.state._titulo === "" ? (<span className="reqCampo">campo requerido</span>) : ''
								}


								{!this.state.mostrarInputChecklist &&<InputContainer fileInput>

									<FileButton onClick={this.clickFileUpload}>
										<Icon size="large" name='attach' className="" /> 
										Anexar Arquivo
									</FileButton>
								</InputContainer>}

								<div>
									{
										this.state._filesToUpload.map((o, index) => <ButtonFile name={o.fileName} index={index} click={this.excludItem} key={index} />)
									}
								</div>

								{!this.state.mostrarInputChecklist && <InputContainer>
									<DescriptionInput value={this.state._descricao} onChange={this.handleDescricao} label='Descrição do Chamado *' placeholder={"Por favor, nos diga o que está ocorrendo..."} />
								</InputContainer>}

								{this.state.mostrarInputChecklist && <>
									<label style={{ fontSize: '1.2rem' }}>DESCRIÇÂO DO CHAMADO *</label>
									<InputMock 
										onClick={this.abrirChecklist}
										disabled={this.state._descricao}
									>
										<span>{this.state._descricao || 'Nos conte o que está acontecendo'}</span>
									</InputMock>
									{this.state._descricao && <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
										<IconButton onClick={this.state.imagensChecklist ? this.abrirGaleria : null}>
											<FaRegImages size={20}/>
										</IconButton>

										<IconButton onClick={this.abrirChecklist}>
											<FaRegEdit size={20} />
										</IconButton>

									</div>}
								</>}

								{
									this.state.showValidate && this.state._descricao === "" ? (<span className="reqCampo">campo requerido</span>) : ''
								}
							</Form>
						</ModalBody>

						<ModalFooter>
							<ModalButton variant="contained" bgColor={'#dc3545'} onClick={this.cancelarAbertura}>
								Cancelar
							</ModalButton>
							<ModalButton variant="contained" bgColor={'#2991d6'} onClick={this.state.mostrarInputChecklist ? this.gravaChamadoComChecklist : this.gravaChamado}>
								Enviar
							</ModalButton>
						</ModalFooter>
					</BaseModal>}

					<StatusFilterContainer isMobile={this.state.isMobile}>

						<CardChamado animate={this.state.activeIndex === 1} click={this.goConluidos} numero={this.state.qtConcluidos} texto="Concluídos " color="#2991D6" icon="thumbs up" />

						<CardChamado animate={this.state.activeIndex === 0} click={this.goPendentes} color="#DC3545" numero={this.state.qtPendentes} icon="meh outline" texto="Pendentes " />
						
					</StatusFilterContainer>

					<ListContainer isMobile={this.state.isMobile}>
						<ListHeader>
							{this.state.windowW > 1024 ? (<BotaoIncluir text="Incluir Chamado" click={this.opeNew} id="btnIncluirChamado" classname="incluirChamado" />) : ""}
						</ListHeader>

						<div>
							<div>
								<ListTab activeIndex={this.state.activeIndex} panes={panes} className="tabsChamados" />
							</div>
						</div>

					</ListContainer>
				</Container>
			</div>
		);
	}
}



















