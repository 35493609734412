import axios from 'axios';
import storage from '../storage/index';
import aux from '../utils/auxiliar';


const APIChecklist = {
	getChecklist: async function (modeloID) {
		try {
			const header = aux.getHeaderPadrao();
			const response = await axios.get(`${storage.getUrlClient()}/modeloChecklist/${modeloID}`, { headers: header, timeout: 50000 });
			if (aux.isResponseValid(response)) {
				return response.data.modeloChecklist;
			} else {
				return { error: true };
			}
		} catch (error) {
			return [];
		}
	}
}

export default APIChecklist;