import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  align-items: center;
  height: 300px;
`


export const Lista = styled.ul`
  overflow-y: auto;
  padding: 10px;
  width: 100%;
`

export const Item = styled.li`
  width: 100%;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  text-align: center;

  :hover{
    background: #eee;
  }
`