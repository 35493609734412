import { useState } from "react";
import BaseModal from "../basemodal";
import { Alternativa, BotaoAnexo, BotaoNavegacao, Container, ContainerAlternativas, ContainerAnimacao, ContainerInputTexto, ContainerNavegacao, ContainerOpcoes, Footer, IndicadorQtdAnexos, InputTexto, Mensagem, Pergunta, Titulo } from "./style";
import principal from "../../theme/principal";

import { FiCamera, FiCameraOff } from "react-icons/fi";
import aux from "../../utils/auxiliar";
import { useEffect } from "react";
import { useContext } from "react";
import { ShowMessageContext } from "../../contexts/showmessage";
import APIChecklist from "../../API/APIChecklist";
import Skeleton from "react-loading-skeleton";




export default function Checklist({ modeloID, fechar, finalizar }){
  

  const [modelo, setModelo] = useState();
  const [perguntaAtual, setPerguntaAtual] = useState(0);
  const [animacaoAtiva, setAnimacaoAtiva] = useState(true);

  const perguntas = modelo ? modelo.itens : [];

  const [respostas, setRespostas] = useState([]);

  const [carregando, setCarregando] = useState(false);
  const [mensagem, setMensagem] = useState('');

  const [podeAnexarFoto, setPodeAnexarFoto] = useState(false);
  const [anexoObrigatorio, setAnexoObrigatorio] = useState(false);

  const [podeComentario, setPodeComentario] = useState(false);
  const [comentarioObrigatorio, setComentarioObrigatorio] = useState(false);


  const [erroAnexo, setErroAnexo] = useState(false);
  const [erroComentario, setErroComentario] = useState(false);

  const { show } = useContext(ShowMessageContext);

  useEffect(() => {
    buscarChecklist();
  }, [])


  

  useEffect(() => {
    setAnimacaoAtiva(false); 
    setAnexoObrigatorio(false);
    setComentarioObrigatorio(false);
    setPodeAnexarFoto(false);
    setPodeComentario(false);

    const timer = setTimeout(() => setAnimacaoAtiva(true), 50);  
    return () => clearTimeout(timer);
  }, [perguntaAtual]);




  async function buscarChecklist() {
    try{
      setCarregando(true);
      const resultado = await APIChecklist.getChecklist(modeloID);

      if(!resultado.error) {
        const respostasAux = resultado.itens.map(pergunta => {
          return {
            item: pergunta.item,
            observacao: "",
            resposta: pergunta.tipo === 1 ? 0: '',
            fotos: [],
            planosAcao: []
          }
        })

        const temAssinatura = resultado.itens.find(item => item.tipo === 4);

        if(temAssinatura){
          show("Checklist inválido: assinatura incompatível com dispositivo", true);
          fechar();
          return;
        }

        setModelo(resultado);
        setRespostas(respostasAux);

      }
    }catch(e){
      show("Algo deu errado.", true)
    }finally{
      setCarregando(false);
    }
  }




  function selecionarAlternativa(alternativa){
    setMensagem('');
    const opcoesAux = perguntas[perguntaAtual].opcoes.find(opcao => opcao.opcao === alternativa);

    validarAnexosEObservacao(opcoesAux);

    const respostaAux = respostas.find(resposta => resposta.item === perguntas[perguntaAtual].item);
    respostaAux.resposta = respostaAux.resposta === alternativa ? 0 : alternativa;

    setRespostas([...respostas]);
  }




  function alterarRespostaTexto(e){
    validarAnexosEObservacao(perguntas[perguntaAtual])
    const respostaAux = respostas.find(resposta => resposta.item === perguntas[perguntaAtual].item);

    respostaAux.resposta = e.target.value;

    setRespostas([...respostas]);
  }


  function validarAnexosEObservacao(opcoes){

    const { midia, comentario } = opcoes;
    
    if(midia === 3) setAnexoObrigatorio(true);
    if(midia === 2) setPodeAnexarFoto(true);

    if(comentario === 3) setComentarioObrigatorio(true);
    if(comentario === 2) setPodeComentario(true);

  }


  function alterarObservacao(e){
    setErroComentario(false);
    setMensagem('');

    const respostaAux = respostas.find(resposta => resposta.item === perguntas[perguntaAtual].item);
    respostaAux.observacao = e.target.value;

    setRespostas([...respostas]);
  }


  function irParaProximaPergunta(){
   
    const respostaValida = validarResposta();

    if(!respostaValida) return;

    setAnimacaoAtiva(true);
    setPerguntaAtual(perguntaAtual + 1);
  }



  function validarResposta(){
    if(perguntas[perguntaAtual].obrigatorio && respostaAtual.resposta === 0){
      mostrarMensagem("Resposta obrigatória")
      return false;
    }

    if(anexoObrigatorio && respostaAtual.fotos.length === 0){
      mostrarMensagem("Anexo obrigatório");
      setErroAnexo(true);
      return false;
    }

    if(comentarioObrigatorio && respostaAtual.observacao === ""){
      mostrarMensagem("Observação obrigatória")
      setErroComentario(true);
      return false;
    }

    return true;
  }

  function voltarPergunta(){
    setPerguntaAtual(perguntaAtual - 1)
  }




  async function anexarImagem(e){
    const arquivos = e.target.files;

    let anexosAux = [];
    for(const arquivo of arquivos){
      try{
        anexosAux.push({
          arqBase64: await aux.getBase64(arquivo)
        })
      }catch(e){
        // show(e);
      }
      
    }

    setErroAnexo(false);
    setMensagem('');

    const respostaAux = respostas.find(resposta => resposta.item === perguntas[perguntaAtual].item);

    respostaAux.fotos = [...respostaAux.fotos, ...anexosAux];
    setRespostas([...respostas]);  
  }



  function mostrarMensagem(texto){
    setMensagem(texto);
  }


  function formatarResposta(){
    const respostaValida = validarResposta();
    if(!respostaValida) return;

    let text = ``;
    let imgs = [];
    
    
    const respostasPreenchidas = respostas.filter(r => r.resposta);


    for (const resposta of respostasPreenchidas) {
      if (resposta.fotos.length > 0) {
        imgs = [...imgs, ...resposta.fotos.map(i => i.arqBase64)];
      }
      const pergunta = perguntas.filter(i => Number(i.item) === Number(resposta.item))[0];
      if (pergunta.tipo === 1) {

        const alternativaEscolhida = pergunta.opcoes.filter(r => r.opcao === Number(resposta.resposta))[0];
        text += `**${pergunta.pergunta}**\n${alternativaEscolhida.resposta}\n\n`

        if (resposta.observacao && resposta.observacao.length > 0) {
          text += `**obs:** ${resposta.observacao}\n\n`
        }

      } else if (pergunta.tipo === 2) {
        text += `**${pergunta.pergunta}**\n${resposta.resposta}\n\n`
      }

    }

    finalizar(text, imgs);
  }



  const respostaAtual = respostas.find(item => item.item === perguntas[perguntaAtual].item);

  const primeiraPergunta = perguntaAtual === 0;
  const ultimaPergunta = (perguntaAtual + 1) === perguntas.length;

  
  return(
    <BaseModal fecharModal={fechar} style={{ zIndex: 999999 }} >
      <Container>
        {carregando && 
        <>
          <Skeleton width={'50%'} height={20} style={{ marginBottom: 20 }}/>
          <Skeleton width={'100%'} height={20}/>
          <Skeleton width={'100%'} height={20}/>
          <Skeleton width={'100%'} height={20}/>
          <Skeleton width={'100%'} height={50} style={{ marginTop: 75}}/>

        </>
        }

        {modelo && !carregando &&<>
        <Titulo>Questão {perguntaAtual + 1}/{perguntas.length}</Titulo>

        <Pergunta>{perguntas[perguntaAtual].pergunta}</Pergunta>
        <ContainerAnimacao ativo={animacaoAtiva}>

          {perguntas[perguntaAtual].tipo === 1 &&
            <ContainerAlternativas>
              {perguntas[perguntaAtual].opcoes.map(item => (
                <li>
                  <Alternativa onClick={() => selecionarAlternativa(item.opcao)} selecionada={respostaAtual.resposta === item.opcao} key={item.opcao}>
                    {item.resposta}
                  </Alternativa>
                </li>
              ))}
            </ContainerAlternativas>
          }


          {perguntas[perguntaAtual].tipo === 2 && 
            <InputTexto rows={5} placeholder="Digite aqui sua resposta" onChange={alterarRespostaTexto} style={{ marginTop: 10 }}/>
          }

        </ContainerAnimacao>
        

        {mensagem && <Mensagem><span>{mensagem}</span></Mensagem>}

        <Footer>

          <InputTexto rows={3} placeholder="Observação" value={respostaAtual.observacao} onChange={alterarObservacao} erro={erroComentario} disabled={!respostaAtual.resposta || (!comentarioObrigatorio && !podeComentario) || perguntas[perguntaAtual].tipo !== 1}/>

          <ContainerOpcoes>
            <BotaoAnexo htmlFor="anexo" >
              {!respostaAtual.resposta || (!anexoObrigatorio && !podeAnexarFoto) ? 
                <FiCameraOff size={25}/> : 
                <>
                  <IndicadorQtdAnexos erro={erroAnexo}><span>{respostaAtual.fotos.length}</span></IndicadorQtdAnexos>
                  <FiCamera size={25} color={erroAnexo ? principal.colors.red : principal.colors.primary}/>
                </>
              }
            </BotaoAnexo>
            <input id='anexo' type="file" accept="image/png, image/jpeg" style={{ display: 'none'}} multiple onChange={anexarImagem} disabled={!respostaAtual.resposta}/>


            <ContainerNavegacao>
              {!primeiraPergunta && 
                <BotaoNavegacao onClick={voltarPergunta}>
                  Voltar
                </BotaoNavegacao>
              }

              <BotaoNavegacao cor={principal.colors.primary} onClick={ultimaPergunta ? formatarResposta : irParaProximaPergunta}>
                {ultimaPergunta ? 'Finalizar' : 'Próximo'}
              </BotaoNavegacao>
            </ContainerNavegacao>
          </ContainerOpcoes>

        </Footer></>}
      </Container>
    </BaseModal>
  )
}