import React from "react";
import aux from "./../../utils/auxiliar";
import Button from '@material-ui/core/Button';
import Alert from "../../components/alert/Alert"
import DateToSelect from "../../components/datetoselect"
import Loading from "../../components/loading/Loading"
import CardListPonto from "./../../components/cardlistponto";
import { Icon } from "semantic-ui-react";
import APIUser from "../../API/APIUser";

import { COR_AJUSTES, COR_ATRASO, COR_FALTA, COR_SEM_MARCACAO } from "./../../utils/constantes";
import { 
  Container, 
  DateFilterContainer,
  Grid,
  StatusCard,
  StatusFilterBody,
  StatusFilterContainer, 
  StatusFilterHeader,
  StatusNumber,
  StatusText
} from "./style";
import { SubTitle } from "../../global/style";





class PontoPage extends React.Component {

  constructor(props) {
    super(props);
    this.refT = React.createRef();

    this.state = {
      pontos: [],
      pontosManipula: [],
      animaCard1: false,
      animaCard2: false,
      animaCard3: false,
      animaCard4: false,
      animaCardTodos: true,
      atrasados: [],
      ajustados: [],
      semMarcacao: [],
      saidasAntecipadas: [],
      comFalta: [],
      faltas: [],
      verbas: [],
      date: aux.getFirstDayOfCurrentMonth(),
      dateF: new Date(),
      show: false,
      alertMessage: "",
      qtPontosSemMarcacao: 0,
      qtPontosAtrasados: 0,
      qtPontosAjustados: 0,
      qtSaidaAtencipada: 0,
      loading: false,
      openOcorrencias: false,
      pis: 0,
      objLimiteData: {}
    }
  }




  async componentDidMount() {

    aux.answerAPP();
    try {
      this.setState({ loading: true });
      const monthLimiter = await APIUser.getMonthLimiter();

      let auxDate = this.handleDataLimit(monthLimiter);

      if (auxDate) {
        this.setState({ date: auxDate });
      } else {
        auxDate = aux.getFirstDayOfCurrentMonth();
      }



      const auxPontos = await APIUser.getEspelhoPonto(auxDate, new Date());

      const pis = await APIUser.getPisUser();
      const pontos = aux.enumeraArray(auxPontos);


      this.setState({ objLimiteData: monthLimiter, pontos: pontos, pontosManipula: pontos, loading: false, pis }, () => this.separaJson());
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }

  showMessage = (message) => {
    this.setState({
      alertMessage: message,
      show: true
    })
  }

  closeMessage = () => {
    this.setState({
      alertMessage: "",
      show: false
    })
  }


  handleDataLimit = ({ referenciaDiaMes, quantidade }) => {
    /* referenciaDiaMes 1=Dias, 2=Mes */
    if (quantidade === 0) return false;
    let auxDate = new Date();
    let date = auxDate;
    if (referenciaDiaMes === 1) {
      date.setDate(date.getDate() - quantidade);
    } else if (referenciaDiaMes === 2) {
      date.setMonth(date.getMonth() - quantidade);
      date.setDate(1)
    }
    return date;
  }




  separaJson = () => {

    // Separa os Atrasados
    let arr = JSON.parse(JSON.stringify(this.state.pontos));
    if (!arr) return [];
    let qtPontosAtrasados = 0;
    let atrasados = arr.filter(o => {
      let result = false;
      if ((o.entrada.temOcorrencia === 1) && (o.mostraPonto)) {
        result = true;
        qtPontosAtrasados++
      }
      return result;
    });


    atrasados = aux.enumeraPagePontos(atrasados);
    this.setState({ atrasados, qtPontosAtrasados });

    //separa os sem marcacao
    arr = JSON.parse(JSON.stringify(this.state.pontos));
    let qtPontosSemMarcacao = 0;
    let semMarcacao = arr.filter(o => {

      let result = false;
      if ((o.entrada.tpMarcacao === "0" || o.entrada.tpMarcacao === "") && (o.mostraPonto)) {
        qtPontosSemMarcacao++;
        result = true
      }

      if ((o.saidaAlmoco.tpMarcacao === "0" || o.saidaAlmoco.tpMarcacao === "") && (o.mostraPonto)) {
        qtPontosSemMarcacao++;
        result = true
      }
      if ((o.retornoAlmoco.tpMarcacao === "0" || o.retornoAlmoco.tpMarcacao === "") && (o.mostraPonto)) {
        qtPontosSemMarcacao++;
        result = true
      }
      if ((o.saida.tpMarcacao === "0" || o.saida.tpMarcacao === "") && (o.mostraPonto)) {
        qtPontosSemMarcacao++;
        result = true
      }

      return result
    });

    semMarcacao = aux.enumeraPagePontos(semMarcacao);
    this.setState({ semMarcacao, qtPontosSemMarcacao })


    //separa os saidas antecipadas
    arr = JSON.parse(JSON.stringify(this.state.pontos));
    let qtSaidaAtencipada = 0;
    let saidasAntecipadas = arr.filter(o => {
      let result = false;
      if ((o.entrada.temOcorrencia === "2") && (o.mostraPonto)) {
        result = true;
        qtSaidaAtencipada++;
      }

      if ((o.saidaAlmoco.temOcorrencia === "2") && (o.mostraPonto)) {
        result = true;
        qtSaidaAtencipada++;
      }

      if ((o.retornoAlmoco.temOcorrencia === "2") && (o.mostraPonto)) {
        result = true;
        qtSaidaAtencipada++;
      }

      if ((o.saida.temOcorrencia === "2") && (o.mostraPonto)) {
        result = true;
        qtSaidaAtencipada++;
      }


      return result;
    });

    saidasAntecipadas = aux.enumeraPagePontos(saidasAntecipadas);
    this.setState({ saidasAntecipadas, qtSaidaAtencipada });




    //separa as faltas
    arr = JSON.parse(JSON.stringify(this.state.pontos));

    let faltas = arr.filter(o => {
      if ((o.temFalta || o.temFalta || o.temFalta || o.temFalta)) return true
      else return false
    });

    faltas = aux.enumeraPagePontos(faltas);
    this.setState({ faltas })

  }


  filterAtrasados = () => { this.setState({ pontosManipula: this.state.atrasados }); }
  filterSemMarcacao = () => this.setState({ pontosManipula: this.state.semMarcacao });
  filterSaidaAtencipada = () => this.setState({ pontosManipula: this.state.saidasAntecipadas });
  filterFaltas = () => this.setState({ pontosManipula: this.state.faltas });
  filterTodos = () => this.setState({ pontosManipula: this.state.pontos });



  animaCard = (card) => {
    if (card === 1) {
      if (this.state.semMarcacao.length === 0) return;
      this.setState({
        animaCard2: false,
        animaCard3: false,
        animaCard4: false,
        animaCard1: true,
        animaCardTodos: false
      })
      this.filterSemMarcacao();

    } else if (card === 2) {

      if (this.state.faltas.length === 0) return;
      this.setState({
        animaCard1: false,
        animaCard3: false,
        animaCard4: false,
        animaCard2: true,
        animaCardTodos: false
      })
      this.filterFaltas();
    } else if (card === 3) {
      if (this.state.atrasados.length === 0) return;
      this.setState({
        animaCard1: false,
        animaCard2: false,
        animaCard4: false,
        animaCard3: true,
        animaCardTodos: false
      })
      this.filterAtrasados();
    } else if (card === 4) {
      if (this.state.saidasAntecipadas.length === 0) return;
      this.setState({
        animaCard1: false,
        animaCard3: false,
        animaCard2: false,
        animaCard4: true,
        animaCardTodos: false
      })
      this.filterSaidaAtencipada();
    } else if (card === 0) {
      if (this.state.pontos.length === 0) return;
      this.setState({
        animaCard1: false,
        animaCard3: false,
        animaCard2: false,
        animaCard4: false,
        animaCardTodos: true
      })

      this.filterTodos();
    }
  }

  handleAnos = (e, data) => {
    const { key, value } = data.options.filter(o => o.value === data.value)[0];
    this.setState({ anoSelected: { key: key, value: value, texto: e.target.textContent } });
  }

  handleMes = (e, data) => {
    const { key, value } = data.options.filter(o => o.value === data.value)[0];
    this.setState({ mesSelected: { key: key, value: value, texto: e.target.textContent } });
  }

  handleDate = (date) => {
    this.setState({ date });
  }

  handleDateF = (dateF) => {
    this.setState({ dateF });
  }

  onSelectDate = () => {

  }

  onSelectDateF = () => {

  }
  toggleOcoorencias = () => {
    this.setState({ openOcorrencias: !this.state.openOcorrencias })
  }

  filterData = async () => {
    try {
      this.setState({ loading: true });
      const { date, dateF } = this.state;
      let pontos = [];
      if (dateF < date) {
        this.setState({ loading: false });
        this.showMessage("Data final inferior a data inicial");
        return;
      } else {
        const response = await APIUser.getEspelhoPonto(new Date(this.state.date), new Date(this.state.dateF));
        if (response) {
          pontos = aux.enumeraPagePontos(response);
        }
        this.setState({ pontos, pontosManipula: pontos }, () => this.separaJson());
      }
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }

  }

  render() {

    const { faltas, pontos, qtSaidaAtencipada, qtPontosAtrasados, qtPontosSemMarcacao } = this.state;
    console.log(this.state.pontosManipula);
    return (
      <>
        <Alert show={this.state.show} title="" message={this.state.alertMessage} onClose={this.closeMessage} />
        <Loading show={this.state.loading} />
        <Container>
            
            <DateFilterContainer>
              <DateToSelect
                objLimiteData={this.state.objLimiteData}
                onSelected={this.onSelectDate}
                date={this.state.date}
                handleDate={this.handleDate}
                labelIni="Início"

                showButton

                onSelectedF={this.onSelectDateF}
                dateF={this.state.dateF}
                handleDateF={this.handleDateF}
                labelFim="Fim"
                monthAndYearOnly={false}

                onClick={this.filterData}

                className="edtPickerMonth"

              />
            </DateFilterContainer>
             
            <Grid>

              <StatusFilterContainer>
                <StatusFilterHeader isOpen={this.state.openOcorrencias}>
                  <SubTitle size={12}>FILTROS</SubTitle>
                  <Button onClick={this.toggleOcoorencias}>
                    <Icon size="large" name="angle down"  />
                  </Button>

                </StatusFilterHeader>
                <StatusFilterBody isOpen={this.state.openOcorrencias}>
                  <StatusCard animate={this.state.animaCardTodos} onClick={() => this.animaCard(0)}>
                    <StatusNumber>
                      <p>{pontos.length} </p>
                    </StatusNumber>

                    <StatusText> 
                      <p>TODOS OS DIAS</p>
                    </StatusText>
                    
                  </StatusCard>

                  {
                    qtPontosSemMarcacao > 0 ? (
                      <StatusCard style={{ color: "#fff", background: COR_SEM_MARCACAO }}  animate={this.state.animaCard1} onClick={() => this.animaCard(1)}>
                        <StatusNumber>
                          <p>{qtPontosSemMarcacao}</p>
                        </StatusNumber>

                        <StatusText>
                          <p>Pontos Sem Marcação</p>
                        </StatusText>
                      </StatusCard>
                    ) : ""
                  }


                  {
                    faltas.length > 0 ? (
                      <StatusCard style={{ color: "#fff", background: COR_FALTA }}  animate={this.state.animaCard2} onClick={() => this.animaCard(2)}>
                        <StatusNumber>
                          <p>{faltas.length}</p>
                        </StatusNumber>

                        <StatusText>
                          <p>Faltas</p>
                        </StatusText>
                      </StatusCard>
                    ) : ""
                  }

                  {
                    qtPontosAtrasados > 0 ? (
                      <StatusCard style={{ color: "#fff", background: COR_ATRASO }}  animate={this.state.animaCard3} onClick={() => this.animaCard(3)}>
                        <StatusNumber>
                          <p>{qtPontosAtrasados}</p>
                        </StatusNumber>

                        <StatusText>
                          <p>Pontos Atrasados</p>
                        </StatusText>
                      </StatusCard>
                    ) : ""
                  }


                  {
                    qtSaidaAtencipada > 0 ? (
                      
                      <StatusCard style={{ color: "#fff", background: COR_AJUSTES }} animate={this.state.animaCard4} onClick={() => this.animaCard(4)}>
                        <StatusNumber>
                          <p>{qtSaidaAtencipada}</p>
                        </StatusNumber>

                        <StatusText>
                          <p>Saída Antecipada</p>
                        </StatusText>
                      </StatusCard>
                    ) : ""
                  }


                </StatusFilterBody>
              </StatusFilterContainer>

              <CardListPonto options={this.state.pontosManipula} pis={this.state.pis} />
            </Grid>
        </Container>
      </>
    )
  }
}

export default PontoPage;



